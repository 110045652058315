import React, { useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close"; // Import Close Icon
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // Import Success Icon

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  style?: React.CSSProperties;
  isUploaded?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (
  { onFileUpload, style },
  isUploaded?: false
) => {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<any>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleConfirmUpload = () => {
    if (file) {
      console.log("file", file);
      onFileUpload(file);
    }
  };

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {/* Image Preview Section */}
      {preview ? (
        <div style={{ position: "relative", textAlign: "center" }}>
          <img
            src={preview}
            alt="Preview"
            style={{
              width: "80px",
              height: "60px",
              objectFit: "cover",
              borderRadius: "8px",
              border: "1px solid #ddd",
              cursor: "pointer",
            }}
            onClick={handleImageClick} // Open modal on click
          />
          {/* Change Photo Icon */}
          <label
            style={{
              position: "absolute",
              bottom: "10px",
              right: "-15px",
              cursor: "pointer",
              backgroundColor: "#ffffff",
              borderRadius: "50%",
              padding: "4px",
              border: `1px solid #36B37E`,
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            <EditOutlinedIcon style={{ fontSize: "20px", color: "#36B37E" }} />
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </label>
        </div>
      ) : (
        // Upload Button for Initial Selection
        <label
          style={{
            cursor: "pointer",
            padding: "10px",
            border: "1px solid #36B37E",
            borderRadius: "4px",
            color: "#36B37E",
            textAlign: "center",
            ...style,
          }}
        >
          Upload
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </label>
      )}

      {/* Upload and Confirm Buttons */}
      {preview && (
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <button
            style={{
              padding: "5px 15px",
              backgroundColor: "#36B37E",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleConfirmUpload}
          >
            Confirm
          </button>
        </div>
      )}

      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto", // Enable scrolling for large content
            padding: "10px", // Add padding for better viewability on smaller screens
          }}
          onClick={handleCloseModal} // Close modal on background click
        >
          <div
            style={{
              position: "relative",
              maxWidth: "70%", // Limit width to 90% of the viewport
              maxHeight: "70%", // Limit height to 90% of the viewport
              overflow: "auto", // Enable scrolling for large images
              borderRadius: "8px",
              backgroundColor: "#fff", // Add background to improve visibility
              padding: "10px", // Add padding around the image
            }}
          >
            {/* Close Button */}
            <button
              onClick={handleCloseModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                border: "2px solid #36B37E", // Border around the close button
                padding: "5px",
                cursor: "pointer",
                color: "#36B37E", // Change color for the icon to match the theme
                fontSize: "20px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </button>
            <img
              src={preview}
              alt="Enlarged Preview"
              style={{
                width: "100%",
                height: "auto", // Maintain aspect ratio
                maxWidth: "100%", // Prevent the image from exceeding modal width
                maxHeight: "100%", // Prevent the image from exceeding modal height
                borderRadius: "8px",
                border: "1px solid #ddd",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
