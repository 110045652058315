import Table1 from "./Busi3Table";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../components/businessNetwok/typography/TypographyText";
import ApiService from "../../api/api";
import { useSelector } from "react-redux";

const Busi3 = () => {
  const newToken = useSelector((state: any) => state.user.Token);
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("");
  const [PageLimit, setPageLimit] = useState<any>(0);
  const [PageSize, setPageSize] = useState<any>(0);

  // userlist for save data from all users
  const [UserList, setUserList] = useState([]);
  const [UserListCount, setUserListCount] = useState<any>({});
  const [searchValue, setsearchValue] = useState("");
  // onchange Event for change state for kyc status for user
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
  };

  //  Get data on mounted through api using UseEffect hooks
  const getUsersList = async () => {
    // arrange values for headers
    const value = userKycStatusValue;
    const page = PageLimit > 0 ? PageLimit : 1;
    const pageSize = PageSize > 0 ? PageSize : 10;

    // condition for params empty or not
    let params = {};
    if (value !== "") {
      Object.assign(params, { due: value, page: page, pageSize: pageSize });
    } else if (searchValue !== "") {
      params = {
        search: searchValue,
      };
    } else {
      params = {
        page: page,
        pageSize: pageSize,
      };
    }

    // userToken check
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/userkyc/getallkyc`,
      params
    );
    if (responseData.status === 200) {
      let count: any = responseData.data.result.dueCounts;
      let data: any = responseData.data.result.results;
      setUserListCount(count);
      setUserList(data);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [userKycStatusValue]);

  return (
    <Box
      justifyContent={"center"}
      component={"div"}
      sx={{
        backgroundColor: "#F7F9FB",
      }}
    >
      <Grid container display={"flex"}>
        <Grid xs={9} sx={{ mt: 2 }}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"24px"}
            sx={{ ml: 8, mt: 2 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Business Networks
          </Typography>
          <Box component={"div"} sx={{ px: 8 }}>
            <Stack display={"flex"} direction={"row"} spacing={2}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Public Sans",
                }}
              >
                Dashboard
              </Typography>
              <Typography>.</Typography>

              <Typography
                fontFamily={"Public Sans"}
                fontSize={"14px"}
                fontWeight={400}
                fontStyle={"normal"}
                color={"rgba(145, 158, 171, 1)"}
              >
                Business Network
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid xs={3} sx={{ mt: 4 }}>
          <Stack>
            <Button
              size="medium"
              sx={{
                // display: "flex",
                color: "white",
                borderRadius: "8px",
                mt: 1,
                mx: 8,
                backgroundColor: "#303981",
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#303981",
                },
              }}
            >
              <AddIcon sx={{ mr: 1 }} /> Add Business Networks
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Box
        boxShadow={1}
        mx={8}
        my={3}
        sx={{ borderRadius: "8px", backgroundColor: "white" }}
      >
        <Tabs
          value={userKycStatusValue}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="secondary"
          sx={{
            px: 2,
            "& .MuiTabs-indicator": {
              ml: 4,
              maxWidth: "90px",
            },
          }}
        >
          <Tab
            value=""
            sx={{ textTransform: "none", px: 2 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={24}
                  chipColor={"rgba(0, 184, 217, 0.16)"}
                  chipFontColor={"#006C9C"}
                />
                <TypographyText color={"black"} text={"All"} />
              </Stack>
            }
          ></Tab>
          <Tab
            value="Active"
            sx={{ textTransform: "none", px: 4 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={4}
                  chipColor={"rgba(54, 179, 126, 0.16)"}
                  chipFontColor={"rgba(27, 128, 106, 1)"}
                />
                <TypographyText
                  color={"rgba(99, 115, 129, 1)"}
                  text={"Active"}
                />
              </Stack>
            }
          />
          <Tab
            value="Disabled"
            sx={{ textTransform: "none", px: 4 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={8}
                  chipColor={"rgba(255, 171, 0, 0.16)"}
                  chipFontColor={"rgba(183, 110, 0, 1)"}
                />
                <TypographyText
                  color={"rgba(99, 115, 129, 1)"}
                  text={"Disabled"}
                />
              </Stack>
            }
          />
        </Tabs>
        <Divider sx={{ color: "rgba(145, 158, 171, 0.24)" }}></Divider>
        <Stack sx={{ my: 0.5, mx: 4 }}>
          {" "}
          <TextField
            fullWidth
            onBlur={getUsersList}
            onChange={(e) => setsearchValue(e.target.value)}
            sx={{ my: 1 }}
            placeholder="Search gst number, mobile number ....."
            InputProps={{
              style: { borderRadius: "10px" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Box>
          <Table1 props={UserList} />
        </Box>
        <Box>
          <Divider></Divider>
          {/* <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={10}
              rowsPerPage={5}
              page={2}
              onPageChange={handlechangeone}
            />
          </Stack> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Busi3;
