import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import Paper from "@mui/material/Paper";
import BalanceItem from "./Balance/BalanceItem";
import ActionableItem from "./ActionItems/ActionableItem";
import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { styled } from "@mui/material/styles";
import MyStatus from "./MyStatus";
import ApiService from "../../../api/api";

const Dashboard = () => {
  const [open, setopen] = useState(true);
  let newToken: any = useSelector((state: any) =>
    state.user.Token ? state.user.Token : null
  );

  const [dashboardData, setdashboardData] = useState<any>({});

  //// Wallet Balance - - - - - - - -  - - -  - - - -  - - - - - -
  const paymentDetailsDashboard = async () => {
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      "/checkerRoutes/dashboard/data"
    );
    if (responseData.status === 200) {
      let orderData = responseData.data;
      setdashboardData(orderData);
      setopen(false);
    } else {
      ErrorMsg("Something went wrong, Please try again!");
      setopen(false);
    }
  };

  // Available, Receivables, Payables & PreClaim Balances //
  useEffect(() => {
    paymentDetailsDashboard();
    // BankBalance();
  }, [newToken]);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    borderRadius: "18px",
    color: theme.palette.text.secondary,
    gap: 2,
  }));

  const colors: { [key: string]: string } = {
    other: "lightgray",
    bio: "lightgreen",
    solar: "yellow",
    wind: "lightblue",
    hydro: "blue",
    nuclear: "orange",
    oil: "darkgrey",
    gas: "gray",
    coal: "black",
  };

  const uData = [
    0, 3000, 2000, 2780, 1890, 2390, 3490, 2400, 1398, 9800, 3908, 4800,
  ];
  const pData = [
    0, 1398, 9800, 3908, 4800, 3800, 4300, 2400, 1398, 9800, 3908, 4800,
  ];
  const xLabels = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div>
      {" "}
      <>
        {/* Step ------- 1 */}
        <Stack sx={{ marginTop: { xs: "110px", md: "50px" } }}>
          <>
            {/* Step 1 (Balance)*/}
            <Box mt={4}>
              <BalanceItem
                open={open}
                BalanceValues={dashboardData && dashboardData}
              />
            </Box>

            {/* Step 2 (Actionable Items)*/}
            <Box mt={2}>
              <ActionableItem
                open={open}
                ActionableValues={dashboardData && dashboardData}
              />
            </Box>
          </>
        </Stack>

        <Grid
          item
          xs={12}
          sm={6}
          mt={10}
          sx={{ mx: { xs: 0, md: 4 } }}
          py={0.5}
        >
          <Item
            sx={{
              mr: "25px",
              // backgroundColor: "#F4F6F8",
              backgroundColor: "white",
              borderRadius: "16px",
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            }}
          >
            <Box sx={{ m: { xs: 0, md: 6 } }}>
              <LineChart
                height={500}
                series={[
                  { data: pData, label: "Received", color: "#36A979" },
                  { data: uData, label: "Spent", color: "#FB9E37" },
                ]}
                xAxis={[
                  { scaleType: "point", data: xLabels, dataKey: "Months" },
                ]}
              />
              <Stack my={2} textAlign={"center"}>
                <Typography
                  sx={{
                    textDecoration: "underline",
                  }}
                  variant="h6"
                  fontFamily={"Public Sans"}
                  fontWeight={600}
                  fontSize={22}
                >
                  {"WC Deployment"}
                </Typography>
              </Stack>
            </Box>
          </Item>
        </Grid>
        <Box mb={8}>
          <MyStatus />
        </Box>
      </>
    </div>
  );
};

export default Dashboard;
