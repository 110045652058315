import * as React from "react";
import {
  Button,
  Chip,
  Grid,
  MenuItem,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
// form
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../../../components/hook-form/FormProvider";
import {
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from "../../../../components/hook-form";

//icons
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

// date component
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { useSelector } from "react-redux";
import { numberToEnglish } from "../../../../utils/Validations";
import ApiService from "../../../../api/api";

type FormValuesProps = {
  title: string;
  paymentType: string;
  PO: string;
  paymentMethod: string;
  desc: string;
  amount: string;
  date: string;
  days: string;
  balanceUsed: number;
  milestone: string;
};

const chipDays = [
  {
    label: "7 days",
    value: 7,
  },
  {
    label: "15 days",
    value: 15,
  },
  {
    label: "30 days",
    value: 30,
  },
  {
    label: "60 days",
    value: 60,
  },
  {
    label: "90 days",
    value: 90,
  },
  {
    label: "150 days",
    value: 150,
  },
  {
    label: "180 days",
    value: 180,
  },
];

function PaymentSummary({
  handleStepper,
  handlePaymentSummary,
  paymentSummaryData,
  isSteplock,
}: any) {
  const newToken = useSelector((state: any) => state.user.Token);
  const [activeDateCount, setActiveDateCount] = React.useState<number>();
  // const REACT_APP_BASE_URL =
  //   process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL;
  const REACT_APP_BASE_URL = "https://dev.api.assuredpay.in";

  const paymentType = [
    { label: "Payment Against Invoice", value: "payment_against_invoice" },
    { label: "Advance Payment", value: "advanced_payment" },
    { label: "Payment on Account", value: "payment_on_account" },
  ];

  // Form Controller
  const FilterSchema = Yup.object().shape({
    title: Yup.string().required("Order Title is Required Field"),
    paymentType: Yup.string().required("Please select Payment Type"),
    paymentMethod: Yup.string().required("Please Select Payment Method"),
    desc: Yup.string().required("Description field is required"),
    PO: Yup.string().required("Please select PO/PI"),
    amount: Yup.string().required("Amount field is required"),
  });

  const defaultValues = {
    title: paymentSummaryData.title || "",
    paymentType: paymentSummaryData.paymentType || "",
    paymentMethod: paymentSummaryData.paymentMethod || "Full Payment",
    PO: paymentSummaryData.PO || "",
    desc: paymentSummaryData.desc || "",
    amount: paymentSummaryData.amount || "",
    date: dayjs(paymentSummaryData.date) || dayjs(new Date()).add(7, "day"),
    days: paymentSummaryData.days || "7",
    milestone: paymentSummaryData.milestone || "2 Milestone",
  };

  const methods = useForm<FormValuesProps | any>({
    resolver: yupResolver(FilterSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = methods;

  //on click next
  const onSubmit = (data: FormValuesProps) => {
    handleStepper(1);
    handlePaymentSummary(data);
    clearDraft();
  };
  React.useEffect(() => {
    setValue("title", paymentSummaryData?.title);
    setValue("paymentType", paymentSummaryData?.paymentType);
    setValue("paymentMethod", paymentSummaryData?.paymentMethod);
    setValue("PO", paymentSummaryData?.PO);
    setValue("desc", paymentSummaryData?.desc);
    setValue("amount", paymentSummaryData?.amount + "");
    setValue("date", paymentSummaryData?.date);
    setValue("days", paymentSummaryData?.days);
    setValue("milestone", paymentSummaryData?.milestone);
  }, [paymentSummaryData]);

  //reset Form
  const resetFormValues = () => {
    setValue("title", "");
    setValue("paymentType", "");
    setValue("PO", "");
    setValue("paymentMethod", "");
    setValue("desc", "");
    setValue("amount", "");
    setValue("date", dayjs(new Date()).add(7, "day"));
    setValue("days", "");
    setActiveDateCount(0);
  };

  const clearDraft = async () => {
    const token = await ApiService.setToken(newToken);
    const responseData: any = await ApiService.delete(
      `/checkerRoutes/draft/milestone`
    );
    if (responseData.status === 200) {
      handleStepper(1);
    }
  };

  const [bankBalanceData, setbankBalanceData] = React.useState<any>({});
  const getAvailableAmount = async () => {
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/checkerRoutes/draft/milestone`
    );
    if (responseData.status === 200) {
      let orderData = responseData.data.result;
      setbankBalanceData(orderData);
    }
  };
  React.useEffect(() => {
    getAvailableAmount();
  }, []);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        p={2}
        my={4}
        mx={2}
        rowGap={2}
        columnGap={4}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <Stack gap={2}>
          <RHFTextField
            name="title"
            label="Order Title (Mandatory)"
            disabled={isSteplock}
          />
          <RHFSelect
            name="paymentType"
            label="Payment Type"
            disabled={isSteplock}
            SelectProps={{
              native: false,
              sx: {
                textTransform: "capitalize",
                "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                  padding: 0,
                },
              },
            }}
          >
            {paymentType.map((item: any) => {
              return (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  onClick={() =>
                    item.value !== "payment_against_invoice" &&
                    setValue("paymentMethod", "Full Payment")
                  }
                  sx={{ backgroundColor: "white", py: 1, fontSize: 13 }}
                >
                  {item.label}
                </MenuItem>
              );
            })}
          </RHFSelect>

          {/* payment method */}
          <RHFRadioGroup
            name="paymentMethod"
            aria-disabled={isSteplock}
            sx={{ display: "flex", flexDirection: "row", gap: 4 }}
            options={
              watch("paymentType") == "payment_against_invoice"
                ? [
                    {
                      label: "Full Payment",
                      value: "Full Payment",
                      disabled: isSteplock,
                    },
                    {
                      label: "Partial Payment",
                      value: "Partial Payment",
                      disabled: isSteplock,
                    },
                  ]
                : [
                    {
                      label: "Full Payment",
                      value: "Full Payment",
                      disabled: isSteplock,
                    },
                  ]
            }
          />
          <RHFTextField
            name="PO"
            label="PO/PI (Mandatory)"
            disabled={isSteplock}
          />
          <RHFTextField
            type="number"
            name="amount"
            label="Invoice Amount"
            disabled={isSteplock}
          />

          {bankBalanceData.availableBalance <= parseInt(watch("amount")) ? (
            <>
              <Typography variant="caption" sx={{ color: "red", ml: 1 }}>
                {"You do not have sufficient funds. "}
              </Typography>
            </>
          ) : (
            <Typography variant="caption" sx={{ color: "#303981", ml: 1 }}>
              {numberToEnglish(parseInt(watch("amount")))}
            </Typography>
          )}

          {watch("paymentMethod") == "Full Payment" && (
            <>
              {/* Date picker */}
              <Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Payment Days"
                      format="DD/MM/YYYY"
                      disabled={isSteplock}
                      minDate={dayjs(new Date()).add(7, "day")}
                      maxDate={dayjs(new Date()).add(181, "day")}
                      value={dayjs(watch("date"))}
                      onChange={(newValue) => {
                        setValue("date", newValue);
                        setActiveDateCount(0);
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                              {
                                color: "white",
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#303981",
                                border: "1px solid",
                                backgroundColor: "#303981",
                              },
                            ".css-1beqopj-MuiPickersYear-yearButton.Mui-selected":
                              {
                                color: "white",
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#303981",
                                border: "1px solid",
                                backgroundColor: "#303981",
                              },
                            ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                              {
                                color: "#303981",
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: "#303981",
                                border: "1px solid",
                                // backgroundColor: "#303981",
                              },
                          },
                        },
                      }}
                      sx={{
                        "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root":
                          {
                            backgroundColor: "white",
                            borderRadius: 2,
                          },
                        "& .css-i4bv87-MuiSvgIcon-root": {
                          color: "#303981",
                        },
                        "& label.Mui-focused": {
                          color: "#303981",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#E0E3E7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B2BAC2",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#303981",
                          },
                        },

                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Stack
                  flexDirection={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                  mt={0.5}
                >
                  {chipDays.map((item: any) => {
                    return (
                      <Chip
                        label={item.label}
                        disabled={isSteplock}
                        sx={
                          activeDateCount == item.value
                            ? {
                                fontSize: 12,
                                backgroundColor: "#D8FBDE",
                                color: "#5BE584",
                                borderColor: "#5BE584",
                                "&:hover": {
                                  backgroundColor: "#D8FBDE",
                                  color: "#5BE584",
                                  borderColor: "#5BE584",
                                },
                              }
                            : { fontSize: 12 }
                        }
                        onClick={() => {
                          setValue(
                            "date",
                            dayjs(new Date()).add(item.value + 1, "day")
                          );
                          setActiveDateCount(item.value);
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </>
          )}

          {watch("paymentMethod") == "Partial Payment" && (
            <RHFRadioGroup
              name="milestone"
              aria-label="Select Milestone"
              aria-disabled={isSteplock}
              sx={{ display: "flex", flexDirection: "row", gap: 4 }}
              options={[
                {
                  label: "2 Milestone",
                  value: "2 Milestone",
                  disabled: isSteplock,
                },
                {
                  label: "3 Milestone",
                  value: "3 Milestone",
                  disabled: isSteplock,
                },
                {
                  label: "4 Milestone",
                  value: "4 Milestone",
                  disabled: isSteplock,
                },
              ]}
            />
          )}
        </Stack>
        <RHFTextField
          name="desc"
          label="Description"
          disabled={isSteplock}
          multiline
          rows={18}
          sx={{
            "& .MuiFormLabel-root": {
              fontFamily: "Public Sans",
              // fontWeight: "500",
              fontSize: "13px",
            },
            "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root": {
              fontSize: "13px",
              backgroundColor: "white",
              borderRadius: 3,
              padding: "13.5px 14px",
            },
            "& label.Mui-focused": {
              color: "#303981",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E3E7",
              },
              "&:hover fieldset": {
                borderColor: "#B2BAC2",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#303981",
              },
            },
          }}
        />
      </Grid>
      <Stack flexDirection={"row"} justifyContent={"space-between"} m={3}>
        <Button
          onClick={() => handleStepper(0)}
          sx={{ color: "#303981" }}
          disabled
        >
          Previous
        </Button>
        <Stack flexDirection={"row"} gap={1}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#303981",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#343d84db",
              },
            }}
            disabled={isSteplock}
            onClick={resetFormValues}
          >
            <RotateLeftIcon sx={{ mr: 0.5 }} />
            Reset
          </Button>

          <Button
            type="submit"
            variant="contained"
            // disabled={!isValid}
            disabled={
              parseInt(watch("amount")) >=
              parseInt(bankBalanceData.availableBalance)
            }
            sx={{
              background: "#303981",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#343d84db",
              },
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default React.memo(PaymentSummary);
