import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Typography14,
  Typography18,
  Typography20,
} from "../../../components/typography/Typography";

const MyStatus = () => {
  const token = useSelector((state: any) => state.user.Token);
  // user Kyc Status for approver or new or rejected state
  // userlist for save data from all users
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    borderRadius: "16px",
    px: 4,
    py: 4,
    color: theme.palette.text.secondary,
  }));

  return (
    <Box
      sx={{
        backgroundColor: "#F7F9FB",
      }}
    >
      <Stack sx={{mx: { sm: 0, md: 4 }}}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={22}
          sx={{ mt: 2 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Dashboard Stats
        </Typography>
      </Stack>
      <Box
        sx={{
          px: { sm: 0, md: 4 },
          mr:"25px",
          mt: 2,
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid xs={2} sm={4} md={4}>
            <Item>
              <Typography18
                text="Stats 1"
                color="rgba(33, 43, 54, 1)"
              ></Typography18>
              <Stack display={"flex"} direction={"row"} py={2}>
                <Stack display={"flex"} direction={"row"} spacing={2}>
                  <Typography14
                    color="rgba(33, 43, 54, 1)"
                    text="+ 3.3%"
                  ></Typography14>
                </Stack>
              </Stack>
              <Typography20
                fontSize={"32px"}
                text={0}
                color="rgba(33, 43, 54, 1)"
              ></Typography20>
            </Item>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <Item>
              <Typography18
                text="Stats 2"
                color="rgba(33, 43, 54, 1)"
              ></Typography18>
              <Stack display={"flex"} direction={"row"} py={2}>
                <Stack display={"flex"} direction={"row"} spacing={2}>
                  <Typography14
                    color="rgba(33, 43, 54, 1)"
                    text="+ 3.3%"
                  ></Typography14>
                </Stack>
              </Stack>
              <Typography20
                fontSize={"32px"}
                text={0}
                color="rgba(33, 43, 54, 1)"
              ></Typography20>
            </Item>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <Item>
              <Typography18
                text="Stats 3"
                color="rgba(33, 43, 54, 1)"
              ></Typography18>
              <Stack display={"flex"} direction={"row"} py={2}>
                <Stack display={"flex"} direction={"row"} spacing={2}>
                  <Typography14
                    color="rgba(33, 43, 54, 1)"
                    text="+ 3.3%"
                  ></Typography14>
                </Stack>
              </Stack>
              <Typography20
                fontSize={"32px"}
                text={0}
                color="rgba(33, 43, 54, 1)"
              ></Typography20>
            </Item>
          </Grid>
        </Grid>
        <Box
          boxShadow={1}
          my={3}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MyStatus;
