import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
import { numberFormat, splitDateConversion } from "../../../utils/Validations";
import {
  Typography12,
  Typography14,
  Typography16,
} from "../../../components/typography/Typography";

export default function ConfigInterestTable(props: any) {
  const orderDetails = props ? props.props : {};
  const [value, setValue] = React.useState("1");

  const TotalOrderAmount = () => {
    //   correction
    let totalOrder_amt = orderDetails.milestones
      ? orderDetails.milestones.reduce(
          (accum: any, item: any) => accum + item.amount,
          0
        )
      : 0;
    return totalOrder_amt;
    return 0;
  };
  const partialTableContainer = (milestones: any) => {
    return (
      <>
        <Box
          // mx={3}
          boxShadow={
            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          }
          borderRadius={"16px"}
          sx={{
            backgroundColor: "rgba(48, 57, 129, 1)",
          }}
        >
          <Table>
            <TableHead
              sx={{
                borderRadius: "10px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    fontSize={fontSizes.sm}
                    fontWeight={600}
                    fontFamily={fontFamilys.publicSans}
                    color={"white"}
                  >
                    Amount
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    // px: 4,
                  }}
                >
                  <Typography
                    fontSize={fontSizes.sm}
                    fontWeight={600}
                    fontFamily={fontFamilys.publicSans}
                    color={"white"}
                  >
                    Payment Date
                  </Typography>
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    // px: 4,
                  }}
                >
                  <Typography
                    fontSize={fontSizes.sm}
                    fontWeight={600}
                    fontFamily={fontFamilys.publicSans}
                    color={"white"}
                  >
                    Days
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "white" }}>
              <>
                <TableRow
                  sx={{
                    boxShadow:
                      "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                    borderRadius: "16px",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 4,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={500}
                    >
                      Partial Payment
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"green"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={500}
                    >
                      {numberFormat((milestones && milestones.amount) || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontWeight={500}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {splitDateConversion(milestones && milestones.date)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontWeight={500}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {milestones && milestones.days}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box display={"flex"} mb={0.5} mx={4}>
        <Stack>
          <Typography16 color="#212B36" text={"Payment Details"}></Typography16>
        </Stack>
      </Box>
      <Box
        mx={3}
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        borderRadius={"16px"}
        sx={{
          backgroundColor: "rgba(48, 57, 129, 1)",
        }}
      >
        <Table>
          <TableHead
            sx={{
              borderRadius: "10px",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "none",
                  //   px: 4,
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Amount
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  //   px: 4,
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Payment Date
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  borderBottom: "none",
                  //   px: 4,
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Days
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  //   px: 4,
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Total
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {orderDetails.order
              ? orderDetails.milestones.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          boxShadow:
                            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                          borderRadius: "16px",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={500}
                          >
                            {numberFormat(item.amount) || 0}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {splitDateConversion(item.date)}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item.days}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            fontWeight={500}
                            color={"rgba(0, 123, 85, 1)"}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item.amount > 0 ? item.amount.toFixed(2) : 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          py: 0.5,
          px: 4,
        }}
      >
        <Stack display={"flex"} direction={"row"} spacing={"auto"}>
          <Box
            sx={{
              maxWidth: "700px",
            }}
          >
            {/* <Typography14 color={"black"} text="Disclaimer"></Typography14>
            <Typography12
              fontSize={11}
              fontWeight={500}
              lineHeight={"12px"}
              text="The displayed Fixed Deposit interest rates are current as of this moment and are subject to change. Rates are updated every 15 seconds to provide you with the most accurate information. Please note that the actual interest rates applicable to your Fixed Deposit may differ at the time of confirmation. We recommend verifying the rates before finalizing any transactions or investments."
            ></Typography12> */}
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Stack display={"flex"} justifyContent={"end"} textAlign={"end"}>
              <Typography14 color="#007B55" text="Total Amount"></Typography14>
              <Typography16
                color="#007B55"
                text={
                  TotalOrderAmount() > 0 ? TotalOrderAmount().toFixed(2) : 0
                }
              ></Typography16>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
