import ApprovalSVG from "../../assets/illustration_upload.svg";
import { Box, Stack, Container, Typography, Paper } from "@mui/material";
import CustomizedSteppers from "../../components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ApiService from "../../api/api";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
// import  from "@mui/icons-material/Email";
import EmailIcon from "@mui/icons-material/MailOutline";

const AdminApproved = () => {
  const location = useLocation();
  const newToken = useSelector((state: any) => state.user.Token);
  let uuid = location.state ? location.state : "";
  const [uuidData, setuuidData] = useState("");
  const navigate = useNavigate();

  // const ApiService =
  const getUserReferenceNumber = async () => {
    if (newToken !== "" && newToken !== undefined) {
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.get("/userkyc/getuuid");
      if (responseData.status === 200) {
        const referenceData = responseData.data.result;
        setuuidData(referenceData);
        if (responseData && responseData.data.profileStatus === 8) {
          navigate("/dashboard/main");
        }
      } else {
        ErrorMsg("Something went wrong, Please try again !");
      }
    }
  };
  useEffect(() => {
    getUserReferenceNumber();
  }, [uuid]);
  return (
    <Box
      sx={{
        backgroundColor: "#F7F9FB",
        height: "90vh",
      }}
    >
      <Stack sx={{ px: "100px" }}>
        <CustomizedSteppers activeStep={4} />
      </Stack>
      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          justifyContent={"center"}
          textAlign={"center"}
          // sx={{ mt: 4 }}
          boxShadow={
            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          }
          border={"1px solid #EEF2FA"}
          borderRadius={"16px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            mx: 4,
            py: 4,
            my: 2,
            backgroundColor: "white",
          }}
        >
          <img src={ApprovalSVG} style={{ maxHeight: "200px" }} alt="" />
          <Typography
            sx={{
              fontSize: "20px",
              mt: 2,
              color: "#36A979",
              fontWeight: 700,
              fontFamily: "Public Sans",
            }}
          >
            {" "}
            Request Sent Successfully
          </Typography>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Stack direction={"row"} display={"flex"} spacing={2}>
              <Typography
                sx={{
                  fontSize: "14px",
                  pt: 0.2,
                  color: "Black",
                  fontWeight: 500,
                  fontFamily: "Public Sans",
                  //   fontStyle: "normal",
                }}
              >
                {" "}
                System Generated Reference No :{" "}
              </Typography>
              <Typography
                fontWeight={700}
                fontFamily={"Public Sans"}
                color="#303981"
              >
                {uuid !== "" ? uuid : uuidData}
              </Typography>
            </Stack>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            justifySelf={"center"}
            sx={{ backgroundColor: "#F7F9FB" }}
            // textAlign={"center"}
            // maxWidth={"700px"}
            mt={4}
            border={"1px solid #EEF2FA"}
            pb={2}
            pt={4}
            px={2}
            // mx={20}
            boxShadow={
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
            }
            borderRadius={"14px"}
          >
            <Stack maxWidth={"600px"}>
              <Typography
                textAlign={"center"}
                sx={{
                  fontSize: "15px",
                  color: "Black",
                  fontWeight: 500,
                  fontFamily: "Public Sans",
                  //   fontStyle: "normal",
                }}
              >
                Please wait for a while, Your document's have been forwarded to
                the AssuredPay Admin/Support team & they will promptly review
                and approve them & contact to you shortly.
              </Typography>
              <Box
                border={"1px solid #EEF2FA"}
                mt={4}
                mb={1}
                py={2}
                sx={{ backgroundColor: "white" }}
                // boxShadow={
                //   "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
                // }
                borderRadius={"14px"}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Stack direction={"row"} gap={0.5} pt={0.3}>
                    <EmailIcon sx={{ pt: 0.2 }} fontSize="small" />

                    <Typography
                      textAlign={"center"}
                      // maxWidth={"500px"}
                      sx={{
                        fontSize: "16px",
                        color: "Black",
                        fontWeight: 500,
                        fontFamily: "Public Sans",
                        //   fontStyle: "normal",
                      }}
                    >
                      Email
                    </Typography>
                  </Stack>
                  <Typography
                    textAlign={"center"}
                    // maxWidth={"500px"}
                    sx={{
                      fontSize: "18px",
                      color: "Black",
                      fontWeight: 500,
                      fontFamily: "Public Sans",
                      //   fontStyle: "normal",
                    }}
                  >
                    : info@assuredpay.in
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            {/* </Stack> */}
          </Box>
          <Stack m={2} spacing={1}>
            <Typography
              textAlign={"center"}
              // maxWidth={"500px"}
              sx={{
                fontSize: "14px",
                color: "#919EAB",
                fontWeight: 500,
                fontFamily: "Public Sans",
                //   fontStyle: "normal",
              }}
            >
              If you have any issues, Please contact us at the provided email,
              and rest assured, our team will reach out to you soon.
            </Typography>
            <Stack
              direction={"row"}
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
              spacing={2}
            >
              <Typography
                // maxWidth={"500px"}
                sx={{
                  fontSize: "18px",
                  color: "Black",
                  pt: 1,
                  fontWeight: 600,
                  fontFamily: "Public Sans",
                  //   fontStyle: "normal",
                }}
              >
                THANKYOU !
              </Typography>
              <Box>
                <img
                  style={{ maxHeight: "42px", maxWidth: "42px" }}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAYAAADhu0ooAAAACXBIWXMAAAsTAAALEwEAmpwYAAADX0lEQVR4nOWZW1PTQBiG9zf6D/QfeKE3zjgjjjfeeRcFBsGktJRaKAUKKAdFYZQBx9MotoWWkxwKtE2a7CbZ10lqwZq2FIZTs8/Me5HttvmebrbNfCEQBAJBIBAEAkEgEAQCQSAQBAJBIBAEAkEgEAQCQSAQBAJBIJd+RpvB1rZg59Ow1XVwU28tUR0GUnYGX6wf+GmnkOMH1RNsE9buEmgyAvqr75+EYW69Bzc1XJloUT+OqtcXnDbn8IwqkKhclX42jE2+7UqwTOI/weqw9CC48d+X0wAOIFfg2DkoZy/PYfNTilITuC0BN9qqc7+nfIIKBa5CYQMewX8TVBWoK9GGkseysaZW1qnhkaLj5mOtKvc6dbf2pkXTW17JSmbzP7FkfXMTZvGGkpGCgnz6ZMEq2UzC3ceNyKvcI1lJZttuXjS5WV/0yX6koVwl8cMAtGToVJJHsitxsOxE3ewuz9QVXfl9iaIT+wHoydMLNpvdr4NXL/pmrxfGBQleG9F3u4GmizWSLSxaTDVX6Ho2BFlTkF8baU3RtWzwxCKXN4LoMMr/uV26gmJ2pPVEu3UF3zeDNX+I9tN9mMwFvO8pBaBl4q0lKv3N85KM0cMApnIBV+5lQcHTBvNlvRelzFDriUpniFIKQl+N+V9Ucm4XSyEYKzH/i0rObaMWhpEe9L+oRGXEtAiMdNT/ohKVMaJGYaSi/heVqIzx4gBo6qX/RSUqY6oQ83QlfCkqURmz+Thost//ohKVkS0sgCbD/hHtpKGa4/PWIuziGsztBeytfjof0bWd+qI5ncJAOR+sT+cuGmVj6GcjnvGMvXFUn2Zw3Kojupk7hajTfOoaB+60H+duBxCZ9c6dNufPJPSMem/wnfSymNtZHDZfu8ftNIAF67PnvImPJh6+0PGgu5y2Hh2RN+zi+rocHAlzuq5QiA3VHO+gvZgw33rGJ833R5/tXDUWLFybBrYJCwNsrKbQnLWIIfNVzdc0XnJ7wpXjMBuGyrXr3ak3QGuuntO5p2CuRPVeTFT1h/f5IVrm2YvKNQRZ7EgmwkZdSQdn3zmXqtPwHjNnULyglbu0h0zOZbxqryNrb5zb/joPCASBQBAIBIFAEAgEgUAQCASBQBAIBIFAEAgEgUAQCASBXHUBl8UfRGgeaYD2izIAAAAASUVORK5CYII="
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminApproved;
