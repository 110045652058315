// Create User Gst - onBoarding
export const BusinessType = [
  "Individual",
  "Limited Liability Partnership (LLP)",
  "One Person Company",
  "Partnership",
  "Private Limited Company",
  "Sole Proprietorship",
];

// All state -
export const State = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu",
  "Kashmir",
  "Daman",
  "Diu",
];

export const identifierType = [
  { value: "orders", label: "ORDERS" },
  { value: "paymentRequest", label: "INVOICE" },
  { value: "invoice", label: "PAYMENT" },
];

// Doucments
export const businessType = {
  company: "Company",
  private: "private Limited Company",
  partnership: "partnership",
  proprietor: "proprietor",
};

export const CompanyDocuments = [
  "AOA",
  "AOF",
  "MOA",
  "MOP",
  "Board Resolution",
  "Present List Of Directors",
  "Company PAN",
  "Certificate Of Incorporation",
  "NTB Declaration",
  "Benificial Ownership Declaration",
  "Escrow RERA And Lender Details",
  "KYC Authorised Signatory Or Partner",
  "Address Proof",
  "KYC",
  "Entity KYC",
  "Approval",
  "Business License",
];
export const privateLimitedDocuments = [
  "AOA",
  "MOA",
  "Company PAN",
  "Certificate Of Incorporation",
  "Address Proof",
  "KYC",
  "Entity KYC",
];

export const partnershipDocuments = [
  "Company PAN",
  "Certificate Of Incorporation",
  "Present List Of Partners",
  "KYC Authorised Signatory Or Partner",
  "Address Proof",
  "KYC Authorised",
  "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed",
];

export const proprietorDocuments = [
  "Company PAN",
  "Certificate Of Incorporation",
  "Present List Of Partners",
  "KYC Authorised Signatory Or Partner",
  "Address Proof",
  "KYC Authorised",
  "Copy Of Partnership Deed Obtained Registered Deed Or Unregistered Deed",
];
