import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Wallet from "../../../../../assets/Wallet.svg";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";
import {
  numberFormat,
  numberToEnglish,
} from "../../../../../utils/Validations";
import ModalDialog from "../../../../../components/Dialog/ModelDialog";
import { useState } from "react";

const Ap_Balance = (props: any) => {
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "AssuredPay Balance";
  const modelSubtitle =
    "This refers to the balance available within your escrow account.";

  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          AssuredPay Balance
          <img src={Wallet} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "9px",
            }}
          >
            My available balance to make payments.
          </Typography>
          <InfoIcon
            sx={{ color: "#FFF", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={modelTitle}
            modelSubtitle={modelSubtitle}
            BgColor={"#36A979"}
            TextColor={"#FFFFFF"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          {numberFormat(props?.Av_Balance.walletBalance?.availableBalance)}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          {props?.Av_Balance?.walletBalance?.availableBalance > 0
            ? numberToEnglish(
                parseInt(props?.Av_Balance?.walletBalance?.availableBalance)
              ).toLocaleUpperCase()
            : " NO BALANCE, PLEASE ADD FUNDS"}
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <LoadingButton
          disabled={props?.Av_Balance?.walletBalance?.availableBalance <= 0}
          size="small"
          sx={{ px: 2 }}
          style={{
            color:
              props?.Av_Balance?.walletBalance?.availableBalance <= 0
                ? "#B0B0B0"
                : "#FFFFFF",
            backgroundColor:
              props?.Av_Balance?.walletBalance?.availableBalance <= 0
                ? "#E0E0E0"
                : "#36A979",
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "11px",
            border: "1px solid #fff",
            fontWeight: "700",
          }}
          type="submit"
        >
          View Statement
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default Ap_Balance;
