import Card from "@mui/material/Card";
import ResistoreIcon from "../../../assets/signup.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OtpVerifyForm from "./OtpVerifyForm";
import { Stack } from "@mui/material";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const OtpVerification = () => {
  return (
    <>
      <Header />
      <Box margin={4} sx={{ flexGrow: 1 }}>
        <Box
          component={"div"}
          marginBottom={4}
          justifyContent={"center"}
          sx={{ display: "flex",m:"auto" }}
          alignItems={"center"}
          maxWidth={"900px"}
        >
          <Grid
            container
            spacing={{ sm: 8, md: 8, lg: 10 }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <Card
                elevation={1}
                sx={{
                  borderRadius: 3,
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 4px 0px rgba(145, 158, 171, 0.20)",
                  px: "30px",
                  py: "32px",
                  mt: 2,
                }}
              >
                <Stack>
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={"18px"}
                    fontWeight={700}
                    lineHeight={"28px"}
                    color={"#334A67"}
                  >
                    Verify Your Business Account
                  </Typography>
                  <Typography
                    component="div"
                    fontSize={"12px"}
                    fontFamily={"Public Sans"}
                    fontWeight={600}
                    color={"#556486"}
                    textAlign={"start"}
                  >
                    OTP sent to your business email and mobile number.
                  </Typography>
                  <Stack sx={{ mt: 2 }}>
                    <OtpVerifyForm />
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={0} md={6} sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
              <Stack
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={ResistoreIcon}
                  style={{
                    margin: "40px 0 0 0",
                    maxHeight: "380px",
                    maxWidth: "380px",
                  }}
                  alt="Illustration"
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default OtpVerification;
