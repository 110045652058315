import { Box, Stack } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Typography12,
  Typography14,
  Typography16,
} from "../../../components/typography/Typography";
import { ReceivableAmtSum, numberFormat } from "../../../utils/Validations";

const ReceivableTableList = (props: any) => {
  const OrderDetails = props ? props.props : {};
  const TableText = props ? props.TableText : "";

  const feesSum = (arr: any) => {
    if (arr.length <= 0) {
      return 0;
    } else {
      const TotalSum = arr.reduce((acc: any, obj: any) => {
        return acc + parseFloat(obj.platformFee);
      }, 0);
      return TotalSum;
    }
  };

  return (
    <>
      <Box display={"flex"} mt={4} mb={2} mx={4}>
        <Stack>
          <Typography16 color="#212B36" text={TableText}></Typography16>
        </Stack>
      </Box>
      <Box
        mx={3}
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        borderRadius={"16px"}
        sx={{
          backgroundColor: "rgba(48, 57, 129, 1)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "none",
                  px: 3,
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Amount Type
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Amount
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Days
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Platform Fees
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Total Receivable
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {OrderDetails.milestones
              ? OrderDetails.milestones.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          // component="th"
                          // scope="row"
                          sx={{
                            borderBottom: "none",
                            px: 3,
                          }}
                        >
                          <Typography
                            fontSize={12}
                            color={"#212B36"}
                            fontFamily={"Public Sans"}
                            fontWeight={400}
                          >
                            {OrderDetails.milestones.length > 1
                              ? ` Milestone ${index + 1}`
                              : "Full Payment"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={12}
                            color={"rgba(245, 131, 54, 1)"}
                            fontFamily={"Public Sans"}
                            fontWeight={400}
                          >
                            {numberFormat(item.amount || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={12}
                            color={"#212B36"}
                            fontWeight={400}
                            fontFamily={"Public Sans"}
                          >
                            {item.days || 0} Days
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={12}
                            fontWeight={400}
                            color={"red"}
                            fontFamily={"Public Sans"}
                          >
                            {numberFormat(item.platformFee || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={12}
                            fontWeight={400}
                            color={"rgba(0, 123, 85, 1)"}
                            fontFamily={"Public Sans"}
                          >
                            {numberFormat(item.amount - item.platformFee || 0)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              : ""}
          </TableBody>
        </Table>
        {/* <Box
          sx={{
            py: 2,
          }}
        >
          <Stack display={"flex"} direction={"row"} spacing={"auto"}>
            <Box
              sx={{
                maxWidth: "620px",
              }}
            >
              <Typography14 text="Disclaimer"></Typography14>
              <Typography12
                fontSize={9}
                fontWeight={400}
                lineHeight={"10px"}
                text="The displayed Fixed Deposit interest rates are current as of this moment and are subject to change. Rates are updated every 15 seconds to provide you with the most accurate information. Please note that the actual interest rates applicable to your Fixed Deposit may differ at the time of confirmation. We recommend verifying the rates before finalizing any transactions or investments."
              ></Typography12>
            </Box>
            <Box display={"flex"} justifyContent={"end"} sx={{ px: 4 }}>
              <Stack display={"flex"} justifyContent={"end"} textAlign={"end"}>
                <Typography12
                  color="#007B55"
                  text="Total Receivable"
                ></Typography12>
                <Typography14
                  color="#007B55"
                  text={numberFormat(TotalInterest()||0)}
                ></Typography14>
              </Stack>
            </Box>
          </Stack>
        </Box> */}
      </Box>
      <Box
        sx={{
          py: 2,
          px: 5,
        }}
      >
        <Stack display={"flex"} direction={"row"} spacing={"auto"}>
          <Box
            sx={{
              maxWidth: "700px",
            }}
          >
            <Typography14 color={"black"} text="Disclaimer"></Typography14>
            <Typography12
              fontSize={11}
              fontWeight={500}
              lineHeight={"12px"}
              text="The displayed Fixed Deposit interest rates are current as of this moment and are subject to change. Rates are updated every 15 seconds to provide you with the most accurate information. Please note that the actual interest rates applicable to your Fixed Deposit may differ at the time of confirmation. We recommend verifying the rates before finalizing any transactions or investments."
            ></Typography12>
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Stack display={"flex"} justifyContent={"end"} textAlign={"end"}>
              <Typography14
                color="#007B55"
                text="Total Receivable"
              ></Typography14>
              <Typography16
                color="#007B55"
                // text={numberFormat(TotalInterest() || 0)}
                text={numberFormat(
                  ReceivableAmtSum(OrderDetails.milestones || []) -
                    feesSum(OrderDetails.milestones || [])
                )}
              ></Typography16>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ReceivableTableList;
