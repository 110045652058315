import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Rating,
  Modal,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import {
  Typography12,
  Typography14,
} from "../../components/typography/Typography";
import { fontFamilys } from "../../utils/FontSize";
import { LoadingButton } from "@mui/lab";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useSelector } from "react-redux";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import ApiService from "../../api/api";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const RateYourSeller = (props: any) => {
  let newToken: any = useSelector((state: any) =>
    state.user.Token ? state.user.Token : null
  );

  const [submitOpen, setsubmitOpen] = useState(false);
  const [deliveryRating, setdeliveryRating] = useState<any>(0);
  const [qualityRating, setqualityRating] = useState<any>(0);
  const [recommondationRating, setrecommondationRating] = useState<any>(0);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);

  const CancleDialogHandle = () => {
    setdeliveryRating(0);
    setqualityRating(0);
    setrecommondationRating(0);
    setRemarks("");
    setsubmitOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: "50%",
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
  };
  const styleCancleRating = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: 400,
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
  };

  const handleSubmitReview = async () => {
    setLoading(true);
    // let params = {};
    let body = {
      deliveryOnTime: deliveryRating,
      qualityOfDelivery: qualityRating,
      recommendation: recommondationRating,
      remarks: remarks,
    };

    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      "/order/rating/65e867d2654b9e5b1b4851e1",
      body
    );
    if (responseData.status === 200) {
      setLoading(false);
      setsubmitOpen(false);
    } else {
      ErrorMsg("Something went wrong, Please try again !");
    }
  };

  const openReviewModel = () => {
    setsubmitOpen(true);
  };

  const isRatingValid = () => {
    if (
      (deliveryRating || qualityRating || recommondationRating) &&
      remarks.trim().length > 100 &&
      remarks.trim().length < 200
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        my={2}
      >
        <Button
          size="medium"
          sx={{
            color: "white",
            borderRadius: "8px",
            px: 2,
            backgroundColor: "#303981",
            textAlign: "right",
            fontFamily: "Public Sans",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#303981",
            },
          }}
          onClick={openReviewModel}
        >
          <Typography14
            color={"white"}
            text={"Rate Your Seller"}
          ></Typography14>
        </Button>
        <Modal
          open={submitOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <Box sx={style}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
              >
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"18px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  sx={{ textDecoration: "underline" }}
                >
                  Rate Your Order
                </Typography>
              </Box>
              <Stack mx={4} mt={3}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"14px"}
                    fontWeight={500}
                    fontStyle={"normal"}
                    color={"#000000"}
                  >
                    Thank you for choosing{" "}
                  </Typography>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"16px"}
                    fontWeight={600}
                    fontStyle={"normal"}
                    // color={"#303981"}
                    color={"#000000"}
                  >
                    {props.orderDetails.target.Legal_Name_of_Business}
                  </Typography>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"14px"}
                    fontWeight={500}
                    fontStyle={"normal"}
                    color={"#000000"}
                  >
                    for order No
                  </Typography>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"16px"}
                    fontWeight={600}
                    fontStyle={"normal"}
                    color={"#F58336"}
                  >
                    {props.orderDetails.order.orderID}.
                  </Typography>
                </Stack>
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"14px"}
                  fontWeight={500}
                  fontStyle={"normal"}
                  // color={"#000000"}
                >
                  We value your feedback. Please rate your order experience on a
                  scale of 1 to 5 stars.
                </Typography>
              </Stack>

              <Box mx={4} my={3}>
                <Stack gap={0.5} my={2}>
                  <Typography14
                    color="#000000"
                    fontWeight={500}
                    text={"1.  How was the delivery of time"}
                  ></Typography14>
                  <Rating
                    value={deliveryRating}
                    onChange={(event, newValue) => {
                      setdeliveryRating(newValue);
                    }}
                  />
                </Stack>

                <Stack gap={0.5} my={2}>
                  <Typography14
                    color="#000000"
                    fontWeight={500}
                    text={"2.  How was the quality Of delivery"}
                  ></Typography14>
                  <Rating
                    value={qualityRating}
                    onChange={(event, newValue) => {
                      setqualityRating(newValue);
                    }}
                  />
                </Stack>

                <Stack gap={0.5} my={2}>
                  <Typography14
                    color="#000000"
                    fontWeight={500}
                    text={
                      "3.  Do you recommended the business profile for others"
                    }
                  ></Typography14>
                  <Rating
                    value={recommondationRating}
                    onChange={(event, newValue) => {
                      setrecommondationRating(newValue);
                    }}
                  />
                </Stack>
                <Stack my={2} spacing={1}>
                  <Typography14
                    color="#000000"
                    fontWeight={600}
                    text={"Write Your Review"}
                  ></Typography14>
                  <Box
                    borderRadius={"16px"}
                    maxWidth={"70%"}
                    sx={{
                      backgroundColor: "#F5F6FA",
                    }}
                    px={1}
                    flexDirection={"row"}
                  >
                    <TextField
                      type="text"
                      name="remarks"
                      placeholder="Type your review here, minimum 100 characters are required !"
                      value={remarks}
                      fullWidth
                      multiline
                      inputProps={{
                        disableUnderline: true,
                      }}
                      onChange={(e) => setRemarks(e.target.value)}
                      rows={4}
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          borderColor: "#919EAB",
                        },
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                        "& .MuiInputBase-root": {
                          height: "fit-content",
                          alignItems: "start",
                          color: "#7A8499",
                          fontSize: "13px",
                          fontFamily: fontFamilys.publicSans,
                          fontWeight: "400",
                          lineHeight: "20px",
                        },
                      }}
                    />
                  </Box>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"12px"}
                    fontWeight={400}
                    fontStyle={"normal"}
                    color={"red"}
                    height={10}
                  >
                    {remarks.trim().length > 199
                      ? `Maxmimum 200 characters are valid, you have entered more then 200 characters `
                      : ""}
                    {remarks.trim().length > 1 && remarks.trim().length < 99
                      ? `Minimum 100 characters are required, you have entered only ${
                          remarks.trim().length + 1
                        }`
                      : ""}
                  </Typography>
                </Stack>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <Stack display={"flex"} direction={"row"} spacing={4}>
                    <LoadingButton
                      size="small"
                      onClick={handleSubmitReview}
                      loading={loading}
                      sx={{
                        color: "white",
                        borderRadius: "8px",
                        px: 3,
                        backgroundColor: !isRatingValid()
                          ? !loading
                            ? "#303981"
                            : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                          : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                        textAlign: "right",
                        fontFamily: "Public Sans",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: !isRatingValid()
                            ? !loading
                              ? "#303981"
                              : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                            : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                        },
                      }}
                      disabled={isRatingValid()}
                    >
                      <Typography12
                        color={"white"}
                        fontWeight={700}
                        text={"Submit"}
                      ></Typography12>
                    </LoadingButton>
                    <Button
                      size="small"
                      onClick={CancleDialogHandle}
                      sx={{
                        color: "white",
                        borderRadius: "8px",
                        px: 3,
                        backgroundColor: `#FF5630`,
                        textAlign: "right",
                        fontFamily: "Public Sans",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: `#FF5630`,
                        },
                      }}
                    >
                      <Typography12
                        color={"white"}
                        fontWeight={700}
                        text={"Decline"}
                      ></Typography12>
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </>
        </Modal>
      </Box>
    </>
  );
};

export default RateYourSeller;
