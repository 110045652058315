import { Box, Stack } from "@mui/system";
import React from "react";
import {
  Typography12,
  Typography14,
  Typography16,
} from "../../../components/typography/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  numberFormat,
  totalReceivableAmount,
} from "../../../utils/Validations";

const PaymentInterestDetails = (props: any) => {
  const interestDetailsArray = props.props.length > 0 ? props.props : [];

  return (
    <Box
      py={3.5}
      sx={{
        borderRadius: "8px",
        maxWidth: "50%",
        backgroundColor: "rgba(223, 227, 232, 1)",
      }}
    >
      <Stack px={3} pb={2}>
        <Typography16
          color="rgba(33, 43, 54, 1)"
          fontWeight="700"
          text="Payment Configure Details"
        ></Typography16>
      </Stack>
      <Box
        mx={3}
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        borderRadius={"8px"}
        sx={{
          backgroundColor: "rgba(48, 57, 129, 1)",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "none",
                  px: 2,
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Amount Type
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Amount
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Days
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Fees
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"white"}
                >
                  Approx Interest
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white", borderRadius: "8px" }}>
            {interestDetailsArray && interestDetailsArray.length > 0
              ? interestDetailsArray.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          boxShadow:
                            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                          borderRadius: "8px",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={13}
                            color={"#212B36"}
                            fontFamily={"Public Sans"}
                            fontWeight={500}
                          >
                            {item.amountType ? "Bank Balance" : "Receivables"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={13}
                            color={
                              item.amountType === "Bank Balance"
                                ? ""
                                : "rgba(245, 131, 54, 1)"
                            }
                            fontFamily={"Public Sans"}
                            fontWeight={500}
                          >
                            {numberFormat(item.amount)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={13}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={"Public Sans"}
                          >
                            {item.paymentDays}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={13}
                            color={"#B71D18"}
                            fontWeight={500}
                            fontFamily={"Public Sans"}
                          >
                            {item.convenienceFees}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={13}
                            fontWeight={500}
                            color={"rgba(0, 123, 85, 1)"}
                            fontFamily={"Public Sans"}
                          >
                            {numberFormat(item.approxInterest)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              : ""}
          </TableBody>
        </Table>
        {interestDetailsArray && interestDetailsArray.length <= 0 ? (
          <Box
            display={"flex"}
            flexDirection={"row"}
            boxShadow={1}
            sx={{ backgroundColor: "white" }}
            height={"8vh"}
            justifyContent={"center"}
            textAlign={"center"}
            alignItems={"center"}
          >
            <Typography12
              color="#B76E00"
              fontSize="10px"
              text="With AssuredPay, You can earn interest on all your payments."
            ></Typography12>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Stack my={3} mx={3}>
        <Typography14
          fontWeight={400}
          color={"#212B36"}
          text="Disclaimer"
        ></Typography14>
        <Stack maxWidth={"76%"}>
          <Typography12
            fontSize={10}
            fontWeight={500}
            lineHeight={"12px"}
            text="The displayed Fixed Deposit interest rates are current as of this moment and are subject to change. Rates are updated every 15 seconds to provide you with the most accurate information. Please note that the actual interest rates applicable to your Fixed Deposit may differ at the time of confirmation. We recommend verifying the rates before finalizing any transactions or investments."
          ></Typography12>
        </Stack>
        <Box display={"flex"} justifyContent={"end"} mt={-9}>
          <Box textAlign={"end"}>
            <Typography14
              color="rgba(0, 123, 85, 1)"
              text="Total Interest"
            ></Typography14>
            <Typography16
              color="rgba(0, 123, 85, 1)"
              text={`${parseFloat(
                totalReceivableAmount(interestDetailsArray)
              ).toFixed(2)}`}
            ></Typography16>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default PaymentInterestDetails;
