import { Visibility, VisibilityOff } from "@mui/icons-material";
import SimpleLoader from "../../components/Loader";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import {
  emailValidation,
  mobileValidation,
  userNameValidation,
  passwordValidation,
} from "../../utils/Validations";
import { Stack } from "@mui/system";
import { saveUser } from "../../redux/slices/user";
import { useDispatch } from "react-redux";
import ApiService from "../../api/api";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";

const UserResistor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [popupOpen, setpopupOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [signup, setSignUp] = useState<any>([]);
  // Email Validation State and Error Msgs State
  const [emailAlreadyExist, setemailAlreadyExist] = useState(false);
  const [EmailVerificationMsg, setEmailVerificationMsg] = useState("");
  const [TermsAndConditionData, setTermsAndConditionData] = useState("");
  const [PrivacyPolicyData, setPrivacyPolicyData] = useState("");
  const [DisclaimerData, setDisclaimerData] = useState("");
  const [alertDialog, setalertDialog] = useState(false);
  const [SelectedTextForCond, setSelectedTextForCond] = useState("");
  const [ConditionValue, setConditionValue] = useState("");

  // Email verification
  const EmailVerification = async () => {
    let email = signup.business_email;
    const E_V = emailValidation(email);
    if (email !== "" && email !== undefined) {
      if (E_V === false) {
        setEmailVerificationMsg("Please provide a valid email address.");
        setemailAlreadyExist(true);
      } else {
        setemailAlreadyExist(false);
        setEmailVerificationMsg("");
      }
    } else {
      setEmailVerificationMsg("");
      setemailAlreadyExist(false);
    }
  };

  // Mobile Validation State and Error Msgs State
  const [mobileAlreadyExist, setmobileAlreadyExist] = useState(false);
  const [MobileVerificationMsg, setMobileVerificationMsg] = useState("");

  // mobile verification function
  const MobileVerification = async () => {
    let mobile = signup.business_mobile;
    const M_V = mobileValidation(mobile);
    if (mobile !== "" && mobile !== undefined) {
      if (M_V === false) {
        setMobileVerificationMsg("Please provide a valid mobile number.");
        setmobileAlreadyExist(true);
      } else {
        setMobileVerificationMsg("");
        setmobileAlreadyExist(false);
      }
    } else {
      setMobileVerificationMsg("");
      setmobileAlreadyExist(false);
    }
  };

  // UserName Validation State and Error Msgs State
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const [UserVerificationMsg, setUserVerificationMsg] = useState("");
  // user varification function
  const UserVerification = async () => {
    let name = signup.username;
    const N_V = userNameValidation(name);
    if (name !== "" && name !== undefined) {
      if (N_V === false) {
        setUserVerificationMsg("Username must have at least four characters.");
        setUserAlreadyExist(true);
      } else {
        setUserVerificationMsg("");
        setUserAlreadyExist(false);
      }
    } else {
      setUserVerificationMsg("");
      setUserAlreadyExist(false);
    }
    if (name !== "" && name !== undefined && N_V === true) {
      const dashboardData: any = await ApiService.post(`/Auth/searchExisting`, {
        username: name,
      });
      if (dashboardData.status === 200) {
        var getUserverification = dashboardData.data;
        if (
          getUserverification.result.found === true &&
          getUserverification.result.active === true
        ) {
          setUserVerificationMsg("User Already Exist");
          setUserAlreadyExist(true);
        }
      } else {
        setUserVerificationMsg("Something went wrong, Please try again");
        setUserAlreadyExist(false);
      }
    }
  };

  // Password Visibility State & Validation-----------------------------
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // -------------------------------------------------------------------

  // Password Validations, State and Error Msg State -----------------------
  const [PasswordVerificationMsg, setPasswordVerificationMsg] = useState("");
  const [PasswordValidation, setPasswordValidation] = useState(false);
  const passwordRegexValidation = () => {
    let pass = signup.password;
    const P_V = passwordValidation(pass);
    if (pass !== "" && pass !== undefined) {
      if (P_V === false) {
        setPasswordVerificationMsg(
          "Password must be 8 characters Ex: Assured@1"
        );
        setPasswordValidation(true);
      } else {
        setPasswordVerificationMsg("");
        setPasswordValidation(false);
      }
    }
  };
  // -------------------------------------------------------------------

  // Confirm Password Visibility State & Validation---------------------
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickshowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const confirmPasswordVerification = () => {
    let password = signup.password;
    let confirmpassword = signup.confirmpassword;
    if (password !== "" && password !== undefined) {
      if (password !== confirmpassword) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }
  };
  // -------------------------------------------------------------------

  // ReferalCode State & Validation---------------------
  const [referalCodeErrorMsg, setreferalCodeErrorMsg] = useState("");
  const [invalidReferalCode, setinvalidReferalCode] = useState(false);
  const ReferalCodeVerification = async () => {
    let refferal_code = signup.refferal_code;
    if (refferal_code !== "" && refferal_code !== undefined) {
      const dashboardData: any = await ApiService.post(
        `/auth/searchexistingrefercode`,
        {
          refercode: refferal_code,
        }
      );
      if (dashboardData.status === 200) {
        let getreferalCodeverification = dashboardData.data;
        if (getreferalCodeverification.result.found === false) {
          setreferalCodeErrorMsg("Invalid Referal Code");
          setinvalidReferalCode(true);
        } else {
          setinvalidReferalCode(false);
        }
      } else {
        setreferalCodeErrorMsg("Something went wrong, Please try again.");
        setinvalidReferalCode(true);
      }
    } else {
      setinvalidReferalCode(false);
      setreferalCodeErrorMsg("");
    }
  };

  // ------------------------------------------------------------

  // User Register Page
  const resistorUser = async (e: any) => {
    e.preventDefault();
    setOpen(true);
    let data1 = new FormData(e.currentTarget);
    let params = {
      business_email: data1.get("business_email"),
      username: data1.get("username"),
      business_mobile: data1.get("business_mobile"),
      password: data1.get("password"),
    };
    if (
      data1.get("refferal_code") !== "" &&
      data1.get("refferal_code") !== undefined
    ) {
      Object.assign(params, { refferal_code: data1.get("refferal_code") });
    }
    Object.assign(params, { role: "Business_User" });
    if (
      (params.business_email &&
        params.business_mobile &&
        params.password &&
        params.username) !== ""
    ) {
      const responseData: any = await ApiService.post(
        `/auth/registerbusiness`,
        params
      );
      if (responseData.status === 200) {
        let userData = responseData.data.result;
        dispatch(saveUser(userData));
        SuccessMsg(
          "Verification Codes has been sent to your registered Email & Mobile Number"
        );
        setOpen(false);
        navigate("/verify");
      } else {
        setOpen(false);
        if (responseData)
          ErrorMsg(
            `${responseData.response.data.errors[0].msg} Please Try Again`
          );
      }
    }
  };

  // Checkbox State
  const [checkBox, setcheckBox] = useState(false);
  const handleCheckbox = (e: any) => {
    if (e.target.checked === true) {
      setcheckBox(true);
    } else {
      setcheckBox(false);
    }
  };
  const getUserValues = (event: any) => {
    const { name, value } = event.target;
    setSignUp((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    const {
      business_email,
      username,
      password,
      confirmpassword,
      business_mobile,
    } = signup;
    if (
      business_email !== undefined &&
      business_email !== "" &&
      username !== undefined &&
      username !== "" &&
      password !== undefined &&
      password !== "" &&
      confirmpassword !== undefined &&
      confirmpassword !== "" &&
      business_mobile !== undefined &&
      business_mobile !== "" &&
      checkBox === true &&
      userAlreadyExist === false &&
      mobileAlreadyExist === false &&
      emailAlreadyExist === false &&
      passwordMatch === false &&
      PasswordValidation === false
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  });
  const getTermsConditionsandPrivatePolicy = async () => {
    const dashboardData: any = await ApiService.get(`/auth/getlegaldocuments`);
    if (dashboardData.status === 200) {
      let termsOfService = dashboardData.data.result[0].termsOfService;
      let privacyPolicy = dashboardData.data.result[0].privacyPolicy;
      let discliamer = dashboardData.data.result[0].termsOfService;
      setTermsAndConditionData(termsOfService);
      setPrivacyPolicyData(privacyPolicy);
      setDisclaimerData(discliamer);
    }
  };
  useEffect(() => {
    getTermsConditionsandPrivatePolicy();
  }, []);
  const HandleClickCloseAlertDialog = () => {
    setalertDialog(false);
  };
  const handleClickConditions = (value: any) => {
    if (value === "Privacy Policy") {
      setSelectedTextForCond("Privacy Policy");
      setConditionValue(PrivacyPolicyData);
    } else {
      setSelectedTextForCond("Terms of Service");
      setConditionValue(TermsAndConditionData);
    }
    setalertDialog(true);
  };

  return (
    <>
      <Box component="form" onSubmit={resistorUser} style={{ marginLeft: 10 }}>
        <TextField
          size="small"
          sx={{
            "& .MuiFormLabel-root": {
              color: "#919EAB",
              fontSize: "13px",
              fontFamily: "Public Sans",
              fontWeight: "500",
              mt: 0.2,
            },
          }}
          error={emailAlreadyExist}
          type="email"
          name="business_email"
          label="Business Email Address"
          helperText={emailAlreadyExist ? EmailVerificationMsg : ""}
          id="email"
          onChange={getUserValues}
          onBlur={EmailVerification}
          InputProps={{
            sx: {
              height: "38px",
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
          }}
        />
        <TextField
          size="small"
          type="number"
          className={"hide-spin-buttons"}
          sx={{
            "& .MuiFormLabel-root": {
              color: "#919EAB",
              fontSize: "13px",

              fontFamily: "Public Sans",
              fontWeight: "500",
              mt: 0.2,
            },
          }}
          error={mobileAlreadyExist}
          helperText={mobileAlreadyExist ? MobileVerificationMsg : ""}
          label="Business Mobile Number"
          name="business_mobile"
          id="mobile"
          onBlur={MobileVerification}
          onClick={() => setpopupOpen(true)}
          onChange={getUserValues}
          InputProps={{
            sx: {
              height: "38px",
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
          }}
        />
        <TextField
          sx={{
            "& .MuiFormLabel-root": {
              color: "#919EAB",
              fontSize: "13px",

              fontFamily: "Public Sans",
              fontWeight: "500",
            },
          }}
          size="small"
          type="text"
          label="Username"
          name="username"
          id="username"
          onBlur={UserVerification}
          error={userAlreadyExist}
          helperText={userAlreadyExist ? UserVerificationMsg : ""}
          inputProps={{ minLength: 4 }}
          onChange={getUserValues}
          InputProps={{
            sx: {
              height: "38px",
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
          }}
        />
        <TextField
          sx={{
            "& .MuiFormLabel-root": {
              color: "#919EAB",
              fontSize: "13px",

              fontFamily: "Public Sans",
              fontWeight: "500",
              mt: 0.2,
            },
          }}
          label="Password"
          type={showPassword ? "text" : "password"}
          error={PasswordValidation}
          helperText={PasswordValidation ? PasswordVerificationMsg : ""}
          size="small"
          name="password"
          onChange={getUserValues}
          onBlur={passwordRegexValidation}
          InputProps={{
            sx: {
              height: "38px",
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                <IconButton
                  sx={{ color: PasswordValidation ? "red" : "" }}
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          onBlur={confirmPasswordVerification}
          sx={{
            "& .MuiFormLabel-root": {
              color: "#919EAB",
              fontSize: "13px",
              fontFamily: "Public Sans",
              fontWeight: "500",
              mt: 0.2,
            },
          }}
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          id="outlined-start-adornment"
          name="confirmpassword"
          size="small"
          error={passwordMatch}
          helperText={passwordMatch ? "Password doesn't match" : ""}
          onChange={getUserValues}
          InputProps={{
            sx: {
              height: "40px",
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  sx={{ color: passwordMatch ? "red" : "" }}
                  onClick={handleClickshowConfirmPassword}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{
            "& .MuiFormLabel-root": {
              color: "#919EAB",
              fontSize: "13px",
              fontFamily: "Public Sans",
              fontWeight: "500",
              mt: 0.2,
            },
          }}
          size="small"
          error={invalidReferalCode}
          helperText={invalidReferalCode ? referalCodeErrorMsg : ""}
          onBlur={ReferalCodeVerification}
          onChange={getUserValues}
          label="Referral Code"
          type="text"
          name="refferal_code"
          InputProps={{
            sx: {
              height: "38px",
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
          }}
          id="referalCode"
        />
        <div style={{ display: "flex" }}>
          <div>
            <Checkbox onChange={handleCheckbox} />
          </div>
          <div>
            <Typography
              component="div"
              fontFamily={"Public Sans"}
              fontSize={"12px"}
              fontWeight={500}
              lineHeight={"16px"}
              color={"#556486"}
              letterSpacing={"0.36px"}
              maxWidth={"250px"}
              sx={{ mt: "10px" }}
            >
              By signing up, I agree to AssuredPay{" "}
              <Stack display={"flex"} direction={"row"} spacing={0.5}>
                <Typography
                  fontSize={"12px"}
                  fontWeight={600}
                  fontStyle={"normal"}
                  onClick={() => handleClickConditions("Terms of Service")}
                  letterSpacing={"0px"}
                  lineHeight={"14px"}
                  color="#2549CA"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Terms of Service{" "}
                </Typography>
                <b style={{ color: "#2549CA", fontWeight: "500" }}>&</b>
                <Typography
                  fontSize={"12px"}
                  fontWeight={600}
                  fontStyle={"normal"}
                  // onClick={handleClickConditions}
                  onClick={() => handleClickConditions("Privacy Policy")}
                  letterSpacing={"0px"}
                  lineHeight={"16px"}
                  color="#2549CA"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {" "}
                  Privacy Policy
                </Typography>
              </Stack>
            </Typography>
          </div>
        </div>

        <Button
          disabled={btnDisabled}
          // color="rgba(145, 158, 171, 0.24)"
          size="small"
          color="inherit"
          sx={{
            maxWidth: "320px",
            mt: "18px",
            marginBottom: "35px",
            borderRadius: "8px",
            backgroundColor: btnDisabled
              ? "rgba(145, 158, 171, 0.24)"
              : "#303981",
            fontFamily: "Public Sans",
            fontWeight: "700",
            color: btnDisabled
              ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
              : "white",
          }}
          fullWidth
          variant="contained"
          type="submit"
        >
          Get Otp{" "}
        </Button>
        <SimpleLoader props={open} />
        {/* <AlertDialogSlide
        props={alertDialog}
        HandleClickCloseAlertDialog={HandleClickCloseAlertDialog}
        SelectedTextForCond={SelectedTextForCond}
        condition={ConditionValue}
        // conditions
      /> */}
      </Box>
      <Modal
        open={popupOpen}
        onClose={() => setpopupOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: 400 },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            transition: "0.3s",
          }}
        >
          {/* <Typography
            id="modal-title"
            variant="h6"
            sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
          >
            Popup Title
          </Typography> */}
          <Typography
            id="modal-description"
            sx={{ mb: 3, textAlign: "center" }}
          >
            Please be aware that the mobile number you use to log in will be
            recorded as your official contact for all future communications.
            Ensure that you provide a number that is currently active and will
            remain accessible for ongoing correspondence.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setpopupOpen(false)}
              sx={{ borderRadius: "20px", padding: "8px 16px" }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserResistor;
