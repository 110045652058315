import { Avatar, Grid, Rating, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Typography16 } from "../../components/typography/Typography";
import { checkValueOfObject } from "../../utils/Validations";

const PaymentBusinessInfo = (props: any) => {
  const BusinessDetailsForPayment = props ? props.props : {};
  return (
    <div>
      <>
        <Stack
          sx={{ mx: 4, pt: 2 }}
          display={"flex"}
          direction={"row"}
          spacing={1}
        >
          {" "}
          <Typography16
            fontFamily={"Public Sans"}
            fontWeight={600}
            color={"#000"}
            text={`${
              BusinessDetailsForPayment &&
              BusinessDetailsForPayment.businessDetails &&
              BusinessDetailsForPayment.businessDetails.Legal_Name_of_Business
            }`}
          ></Typography16>
        </Stack>
        <Box
          mx={4}
          my={2}
          sx={{
            borderRadius: "16px",
            backgroundColor: "rgba(249, 250, 251, 1)",
            border: "1px solid rgba(223, 227, 232, 1)",
          }}
        >
          <Stack
            sx={{ mx: 4, py: 2 }}
            display={"flex"}
            direction={"row"}
            spacing={1}
          >
            <Typography16
              fontFamily={"Public Sans"}
              sx={{ mt: 4, pb: 2 }}
              fontWeight={600}
              color={"#000"}
              text={`About the seller`}
            ></Typography16>
          </Stack>
          <Grid
            container
            sx={{
              py: 1,
              px: 6,
              "& .MuiPaper-root": {
                padding: "2px",
              },
            }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 0, p: 0 }}
              xs={2}
              sm={4}
              md={4}
            >
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <Avatar
                    src={
                      BusinessDetailsForPayment &&
                      BusinessDetailsForPayment.businessDetails &&
                      BusinessDetailsForPayment.businessDetails.aadharPhotoLink
                    }
                    alt=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{ m: 0, p: 0 }}
                    fontFamily={"Public Sans"}
                    fontSize={11}
                    color={"#919EAB"}
                  >
                    {" "}
                    Authorised Business Representative
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    color={"#212B36"}
                    fontWeight={600}
                    sx={{ m: 0, p: 0 }}
                  >
                    {(BusinessDetailsForPayment &&
                      BusinessDetailsForPayment.businessDetails &&
                      BusinessDetailsForPayment.businessDetails
                        .nameInAadhaar) ||
                      "N/A"}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 0, p: 0 }}
              xs={2}
              sm={4}
              md={4}
            >
              <Typography
                sx={{ m: 0, p: 0 }}
                fontFamily={"Public Sans"}
                fontSize={11}
                color={"#919EAB"}
              >
                {" "}
                Contact Details
              </Typography>

              <Typography
                fontSize={"14px"}
                fontStyle={"normal"}
                fontFamily={"Public Sans"}
                color={"#212B36"}
                fontWeight={600}
                sx={{ m: 0, p: 0 }}
              >
                {" " +
                  " " +
                  (BusinessDetailsForPayment &&
                    BusinessDetailsForPayment.userDetails &&
                    BusinessDetailsForPayment.userDetails.business_email) ||
                  "N/A"}
              </Typography>
              <Typography
                fontSize={"14px"}
                fontStyle={"normal"}
                fontFamily={"Public Sans"}
                color={"#212B36"}
                fontWeight={600}
                sx={{ m: 0, p: 0 }}
              >
                {" " +
                  " " +
                  (BusinessDetailsForPayment &&
                    BusinessDetailsForPayment.userDetails &&
                    BusinessDetailsForPayment.userDetails.business_mobile) ||
                  "N/A"}
              </Typography>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 0, p: 0 }}
              xs={2}
              sm={4}
              md={4}
            >
              <Typography
                sx={{ m: 0, p: 0 }}
                fontFamily={"Public Sans"}
                fontSize={11}
                color={"#919EAB"}
              >
                Address{" "}
              </Typography>
              <Typography
                fontSize={"14px"}
                fontStyle={"normal"}
                fontFamily={"Public Sans"}
                color={"#212B36"}
                fontWeight={600}
                sx={{ m: 0, p: 0 }}
              >
                {(BusinessDetailsForPayment &&
                  BusinessDetailsForPayment.businessDetails &&
                  BusinessDetailsForPayment.businessDetails
                    .Place_of_Business) ||
                  "N/A"}
              </Typography>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 0, p: 0 }}
              xs={2}
              sm={4}
              md={4}
            >
              <Typography
                sx={{ m: 0, p: 0 }}
                fontFamily={"Public Sans"}
                fontSize={11}
                color={"#919EAB"}
              >
                {" "}
                GST
              </Typography>
              <Typography
                fontSize={"14px"}
                fontStyle={"normal"}
                fontFamily={"Public Sans"}
                color={"#212B36"}
                fontWeight={600}
                sx={{ m: 0, p: 0 }}
              >
                {(BusinessDetailsForPayment &&
                  BusinessDetailsForPayment.businessDetails &&
                  BusinessDetailsForPayment.businessDetails
                    .GSTIN_of_the_entity) ||
                  "N/A"}
              </Typography>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 0, p: 0 }}
              xs={2}
              sm={4}
              md={4}
            >
              <Typography
                sx={{ m: 0, p: 0 }}
                fontFamily={"Public Sans"}
                fontSize={11}
                color={"#919EAB"}
              >
                {" "}
                CIN_NUMBER
              </Typography>
              <Typography
                fontSize={"14px"}
                fontStyle={"normal"}
                fontFamily={"Public Sans"}
                color={"#212B36"}
                fontWeight={600}
                sx={{ m: 0, p: 0 }}
              >
                {(BusinessDetailsForPayment &&
                  BusinessDetailsForPayment.businessDetails &&
                  BusinessDetailsForPayment.businessDetails
                    .GSTIN_of_the_entity) ||
                  "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Box
            mx={4}
            my={2}
            py={1.5}
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
              border: "1px solid rgba(223, 227, 232, 1)",
              maxWidth: "670px",
            }}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              textAlign={"start"}
              spacing={8}
            >
              <Stack
                display={"flex"}
                direction={"row"}
                textAlign={"start"}
                spacing={5}
                sx={{
                  px: 1.5,
                  py: 2,
                  borderRadius: "8px",
                  //   backgroundColor: "#F9FAFB",
                }}
              >
                <Box sx={{ px: 0.5 }}>
                  <Typography
                    component="legend"
                    fontSize={"11px"}
                    color={"#919EAB"}
                  >
                    Rating
                  </Typography>
                  <Rating
                    name="simple-controlled"
                    value={4}
                    size="small"
                    disabled
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                  />
                </Box>
                <Box>
                  <Typography
                    component="legend"
                    fontSize={"11px"}
                    color={"#919EAB"}
                  >
                    Since
                  </Typography>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    fontWeight={600}
                  >
                    2023
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="legend"
                    fontSize={"12px"}
                    color={"#919EAB"}
                  >
                    Orders Full-filed
                  </Typography>
                  <Stack spacing={1} display={"flex"} direction={"row"}>
                    <Typography fontSize={"12px"} fontFamily={"Public Sans"}>
                      30/100
                    </Typography>
                  </Stack>
                </Box>
                <Box>
                  <Typography
                    component="legend"
                    fontSize={"12px"}
                    color={"#919EAB"}
                  >
                    Connections
                  </Typography>
                  <Stack spacing={1} display={"flex"} direction={"row"}>
                    <Typography fontSize={"12px"} fontFamily={"Public Sans"}>
                      30
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Box
                sx={{ backgroundColor: "#F9FAFB", borderRadius: "8px" }}
              ></Box>
            </Stack>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default PaymentBusinessInfo;
