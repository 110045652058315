import RemainingIcon from "../../assets/ic_info.svg";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Container,
  Typography,
  Button,
  TextField,
  Paper,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import ProgressBarCircular from "../../components/ProgressBarCircular";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { characterUpperCase } from "../../utils/Validations";
import ApiService from "../../api/api";
import { useSelector } from "react-redux";

const PANVerify = () => {
  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.user.Token);
  const [UserPANNumber, setUserPANNumber] = useState("");
  const [OtpbtnDisabled, setOtpBtnDisabled] = useState(true);
  const [verifyPanStatus, setverifyPanStatus] = useState(false);
  const [getUserPanData, setgetUserPanData] = useState([]);
  const [errorMsgForPan, seterrorMsgForPan] = useState(false);
  const [RemainingCount, setRemainingCount] = useState(0);
  const [RemainingCountStatus, setRemainingCountStatus] = useState(false);
  const [verifyLoading, setverifyLoading] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  // verify Adhar Page
  const handleOnSubmitPanVerify = async (e: any) => {
    e.preventDefault();
    setOtpBtnDisabled(true);
    setverifyLoading(true);
    const data = new FormData(e.currentTarget);
    let userPAN = data.get("UserPANNumber");
    if (userPAN !== "") {
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.post("/userkyc/verifypan", {
        PanNumber: userPAN,
      });
      if (responseData.status === 200) {
        let left_attempt = responseData.data.result.left_attempt;
        setgetUserPanData(responseData.data.results);
        setRemainingCountStatus(true);
        setRemainingCount(left_attempt);
        setverifyPanStatus(true);
        setverifyLoading(false);
      } else {
        setOtpBtnDisabled(true);
        seterrorMsgForPan(true);
        setverifyLoading(false);
        let left_attempt = responseData.response.data.left_attempt;
        setRemainingCount(left_attempt);
        setRemainingCountStatus(true);
      }
    }
  };

  const handleOnchangePAN = (e: any) => {
    seterrorMsgForPan(false);
    const TrimGst = e.target.value ? e.target.value.trim() : "";
    setUserPANNumber(TrimGst);
    if (e.target.value && TrimGst.length === 10) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
  };
  const handleOnClick = () => {
    navigate("/auth/panuploadfiles");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F7F9FB",
        height: "90vh",
      }}
    >
      <Stack>
        <CustomizedSteppers activeStep={2} />
      </Stack>
      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          // boxShadow={"1"}
          //   justifyContent={"center"}
          boxShadow={
            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          }
          border={"1px solid #EEF2FA"}
          borderRadius={"16px"}
          // sx={{
          //   backgroundColor: "rgba(48, 57, 129, 1)",
          // }}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            mx: 4,
            py: 3,
            mt: 2,
            backgroundColor: "white",
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 } }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={21}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Enter Authorised Business PAN details
              </Typography>
            </Grid>
            {/* <Grid container columnSpacing={1} sx={{ order: { xs: 12, sm: 2 } }}>
              <Grid>
                <ProgressBarCircular percentage={70} />
              </Grid>
            </Grid> */}
          </Grid>
          <Box
            sx={{ my: 3 }}
            component="form"
            onSubmit={handleOnSubmitPanVerify}
          >
            <Stack direction={"row"}>
              <TextField
                label="Enter Your PAN Number"
                size="small"
                type="text"
                disabled={verifyPanStatus}
                name="UserPANNumber"
                value={characterUpperCase(UserPANNumber)}
                error={errorMsgForPan}
                onChange={handleOnchangePAN}
                helperText={
                  !errorMsgForPan
                    ? ""
                    : "Provided details doesn’t match with your information."
                }
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    minWidth: "23vw",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    Height: "20px",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "Red",
                    fontFamily: "Public Sans",
                    fontWeight: "600",
                    fontSize: "8",
                  },
                }}
              />{" "}
              {verifyPanStatus ? (
                <Stack direction="row" spacing={0.5} sx={{ ml: "20px", mt: 1 }}>
                  <>
                    <DoneIcon
                      style={{
                        fontSize: "22px",
                        color: "#36B37E",
                      }}
                    />{" "}
                    <Typography
                      fontWeight={600}
                      fontSize={13}
                      fontFamily={"Public Sans"}
                      color={"#36B37E"}
                    >
                      {" "}
                      {/* PAN is verified Successfully */}
                      Congratulations, On the successful verification of your
                      PAN with AssuredPay !
                    </Typography>
                  </>
                </Stack>
              ) : (
                <Box mx={2}>
                  <LoadingButton
                    size="medium"
                    loading={verifyLoading}
                    disabled={OtpbtnDisabled}
                    sx={{ px: 3 }}
                    style={{
                      textTransform: "none",
                      backgroundColor: OtpbtnDisabled
                        ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                        : "#00AB55",
                      color: OtpbtnDisabled ? "" : "white",
                      borderRadius: "8px",
                      fontSize: "14px",
                      fontWeight: "700",
                      // height: "4vh",
                    }}
                    type="submit"
                  >
                    Verify
                  </LoadingButton>
                </Box>
              )}
            </Stack>
            {RemainingCountStatus ? (
              <Stack
                m={1}
                display={"flex"}
                direction={"row"}
                spacing={0.5}
                alignItems="center"
              >
                <img
                  src={RemainingIcon}
                  style={{
                    height: "16px",
                    width: "16px",
                    display: "block", // Ensure the image is rendered
                    objectFit: "contain", // Prevent distortion
                  }}
                  alt="Remaining Icon"
                />
                <Typography
                  fontSize={12}
                  color={"var(--text-light-secondary, #637381)"}
                  fontStyle={"normal"}
                  fontFamily={"Public Sans"}
                  fontWeight={400}
                >
                  Remaining attempt: {RemainingCount || 0}
                </Typography>
              </Stack>
            ) : (
              // )}
              ""
            )}
          </Box>
          <Box height={"35vh"}></Box>
          <Stack display={"flex"} direction={"row"}>
            <Button
              size="small"
              disabled={!verifyPanStatus}
              onClick={handleOnClick}
              sx={{
                textTransform: "none",
                px: 3,
                py: 1,
                fontSize: "12px",
                backgroundColor: verifyPanStatus
                  ? "#303981"
                  : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                fontFamily: "Public Sans",
                fontWeight: "500",
                borderRadius: "8px",
                color: verifyPanStatus ? "white" : "black",
                "&:hover": {
                  backgroundColor: verifyPanStatus
                    ? "#303981"
                    : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                },
              }}
            >
              Continue
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default PANVerify;
