export const changeTextAdharProfile = (value: any) => {
  if (value === "aadharCO") {
    return "Care_of";
  } else if (value === "aadharGender") {
    return "Gender";
  } else if (value === "aadharCountry") {
    return "Country";
  } else if (value === "aadharPO") {
    return "PO";
  } else if (value === "nameInAadhaar") {
    return "Name";
  } else if (value === "distInAadhar") {
    return "Distict";
  } else if (value === "aadharPincode") {
    return "Pincode";
  } else if (value === "aadharNumber") {
    return "Aadhar No";
  } else if (value === "aadharDOB") {
    return "Date of Birth";
  } else if (value === "_id") {
    return "Id";
  } else if (value === "aadharState") {
    return "State";
  } else if (value === "aadharHouse") {
    return "House";
  } else if (value === "aadharStreet") {
    return "Street";
  }
};

export const changeTextGstProfile = (value: any) => {
  if (value === "Constitution_of_Business") {
    return "Constitution of Business";
  } else if (value === "Date_of_Registration") {
    return "Date of Registration";
  } else if (value === "Business_PAN") {
    return "Business PAN";
  } else if (value === "Legal_Name_of_Business") {
    return "Legal Name of Business";
  } else if (value === "Nature_of_Business_Activity") {
    return "Nature of Business Activity";
  } else if (value === "Nature_of_Place_of_Business") {
    return "Nature of Place of Business";
  } else if (value === "Place_of_Business") {
    return "Place of Business";
  } else if (value === "State") {
    return "State";
  } else if (value === "Taxpayer_Type") {
    return "Taxpayer Type";
  } else if (value === "Trade_Name") {
    return "Trade Name";
  } else if (value === "GSTIN_of_the_entity") {
    return "GSTIN of the entity";
  }
};
