import { Typography } from "@mui/material";

export const Typography22 = (props: any) => {
  return (
    <Typography
      component="div"
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : "22px"}
      fontWeight={props.fontWeight ? props.fontWeight : 700}
      lineHeight={"30px"}
      sx={props.sx ? props.sx : {}}
      color={props.color ? props.color : "#334A67"}
      fontStyle={"normal"}
    >
      {props.text}
    </Typography>
  );
};

export const Typography20 = (props: any) => {
  return (
    <Typography
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : "20px"}
      fontWeight={props.fontWeight ? props.fontWeight : 700}
      lineHeight={props.lineHeight ? props.lineHeight : "30px"}
      sx={props.sx ? props.sx : {}}
      color={props.color ? props.color : ""}
      fontStyle={props.fontStyle ? props.fontStyle : "normal"}
    >
      {props.text}
    </Typography>
  );
};
export const Typography18 = (props: any) => {
  return (
    <Typography
      fontFamily={"Public Sans"}
      fontSize={"18px"}
      fontWeight={props.fontWeight ? props.fontWeight : 600}
      lineHeight={"26px"}
      sx={props.sx ? props.sx : {}}
      color={props.color ? props.color : "var(--text-light-secondary, #637381)"}
      fontStyle={"normal"}
    >
      {props.text}
    </Typography>
  );
};
export const Typography16 = (props: any) => {
  return (
    <Typography
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : "16px"}
      fontWeight={props.fontWeight ? props.fontWeight : 600}
      lineHeight={"24px"}
      sx={props.sx ? props.sx : {}}
      color={props.color ? props.color : "var(--text-light-secondary, #637381)"}
      fontStyle={"normal"}
    >
      {props.text}
    </Typography>
  );
};
export const Typography14 = (props: any) => {
  return (
    <Typography
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : 14}
      fontWeight={props.fontWeight ? props.fontWeight : 600}
      lineHeight={"22px"}
      sx={props.sx ? props.sx : {}}
      color={props.color ? props.color : "var(--text-light-secondary, #637381)"}
      fontStyle={"normal"}
    >
      {props.text}
    </Typography>
  );
};
export const Typography12 = (props: any) => {
  return (
    <Typography
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : "12px"}
      fontWeight={props.fontWeight ? props.fontWeight : 400}
      lineHeight={props.lineHeight ? props.lineHeight : "20px"}
      sx={props.sx ? props.sx : {}}
      color={props.color ? props.color : "#556486"}
      fontStyle={"normal"}
    >
      {props.text}
    </Typography>
  );
};
