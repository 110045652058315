import { Avatar, Button, Divider, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import {
  formatString,
  numberFormat,
  splitDateConversion,
} from "../../../utils/Validations";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as io from "socket.io-client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ApiService from "../../../api/api";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import { Typography12 } from "../../../components/typography/Typography";

const Main = () => {
  const location = useLocation();
  const orderId = location.state && location.state.orderDetails.order;
  const newToken = useSelector((state: any) => state.user.Token);
  const navigate = useNavigate();
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";

  // const socket = io.connect(baseURL, {
  //   query: { token: newToken.toString() },
  // });

  const [message, setMessage] = useState("");
  const [prevMessage, setPrevMessage] = useState<any>([]);
  const [orderDetails, setorderDetails] = useState<any>({});
  const [messageReceived, setMessageReceived] = useState(
    "This is the automated msg for getting data and checking the test format with angle"
  );

  const [show, setShow] = useState(false);
  const userSaveData = useSelector((state: any) =>
    state.user.userSave.username ? state.user.userSave : ""
  );

  const conversationId = orderId && orderId.conversation;
  const joinRoom = () => {
    // socket.emit("joinRoom", conversationId);
  };

  const sendMessage = () => {
    const data = { conversationId, message };
    const newMessage = {
      _id: new Date().toISOString(),
      sender: { _id: userSaveData?._id },
      message: message,
      senderKyc: { aadharPhotoLink: userSaveData?.aadharPhotoLink },
    };
    // socket.emit("sendMessage", data);
    setPrevMessage((prevMessages: any[]) => [...prevMessages, newMessage]);
    setMessage("");
    setShow(true);
  };

  // useEffect(() => {
  //   socket.on("messages", (data: any) => {
  //     setPrevMessage(data);
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   socket.on("message", (data: any) => {
  //     setMessageReceived(data?.message);
  //   });
  // }, [show]);

  useEffect(() => {
    if (conversationId && conversationId) {
      joinRoom();
    }
  }, []);

  const [open, setopen] = useState(false);
  const getOrderDetails = async () => {
    setopen(true);
    let id = orderId ? orderId._id : "";
    if (newToken !== "" && newToken !== undefined) {
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.get(
        `/checkerRoutes/order/${id}`
      );
      if (responseData.status === 200) {
        let referenceData = responseData.data.data;
        let array = [];
        for (let i in referenceData.milestones) {
          array.push(referenceData.milestones[i]);
        }
        referenceData.milestones = array.flat(1);
        setopen(false);
        setorderDetails(referenceData);
      } else {
        ErrorMsg("Something went wrong,Please try again !");
      }
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  }

  // Ref to the last message element
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {!open ? (
        <>
          <Box mx={4} sx={{ my: { xs: 10, md: 4 } }} py={1}>
            <Box mt={4}>
              <Stack direction={"row"} gap={1}>
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"24px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  color={"#000000"}
                >
                  Order Id :{" "}
                </Typography>
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"24px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  color={"rgb(245, 131, 54)"}
                >
                  {" "}
                  {(orderDetails &&
                    orderDetails.order &&
                    orderDetails.order.orderID) ||
                    "N/A"}
                </Typography>
              </Stack>
            </Box>

            <Stack spacing={1}>
              <Typography
                fontSize={14}
                sx={{
                  fontWeight: 400,
                  fontFamily: "Public Sans",
                  color: "#212B36",
                }}
              >
                Business Order
              </Typography>
              <Typography
                fontSize={12}
                fontFamily={"Public Sans"}
                fontWeight={400}
                fontStyle={"normal"}
                color={"#212B36"}
              >
                {(orderDetails &&
                  orderDetails.order &&
                  splitDateConversion(orderDetails.order.created_at)) ||
                  "N/A"}
              </Typography>
            </Stack>
            <Box display={"flex"} justifyContent={"end"} mt={-10}>
              <Button
                size="small"
                onClick={() => navigate(-1)}
                sx={{ backgroundColor: "#303981", px: 1 }}
                style={{
                  margin: "20px 0 0 0",
                  marginBottom: "20px",
                  borderRadius: "8px",
                  backgroundColor: "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "600",
                  color: "white",
                  textTransform: "none",
                }}
              >
                <ArrowBackIcon fontSize="small" sx={{ height: "15px" }} /> Back{" "}
              </Button>
            </Box>
            <Box
              justifyContent={"center"}
              component={"div"}
              borderRadius={"12px"}
              sx={{
                backgroundColor: "white",
                minHeight: "70vh",
              }}
            >
              <Box mx={2} py={1.5} mt={2}>
                <Stack direction={"row"} spacing={1}>
                  <Box>
                    <Avatar />
                  </Box>
                  <Box>
                    <Typography>
                      Order Id:{" "}
                      {(orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.orderID) ||
                        "N/A"}
                    </Typography>
                    <Typography color="#637381" fontSize={12}>
                      Active Now
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box sx={{ flex: "9" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100vh",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        flex: "1",
                        overflowY: "auto",
                        px: 2,
                        py: 2,
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      <Stack spacing={1.5}>
                        {prevMessage.map((item: any) =>
                          item?.sender?._id !== userSaveData?._id ? (
                            <Stack
                              key={item._id}
                              marginRight="auto"
                              ml={3}
                              mt={1}
                              maxWidth={"fit-content"}
                            >
                              <Box>
                                <Stack direction={"row"} spacing={1.5}>
                                  <Avatar
                                    src={item?.senderKyc?.aadharPhotoLink}
                                    sx={{ height: 30, width: 30 }}
                                  />{" "}
                                </Stack>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  backgroundColor: "#F4F6F8",
                                  px: 1,
                                  py: 0.5,
                                  mx: 1,
                                  borderRadius: "8px",
                                }}
                              >
                                <Typography12
                                  text={item.message}
                                ></Typography12>
                                <Stack mt={1.5} ml={1}>
                                  <Typography12
                                    fontSize={9}
                                    text={getCurrentTime()}
                                  ></Typography12>
                                </Stack>
                              </Box>
                            </Stack>
                          ) : (
                            <Box
                              display={"flex"}
                              flexDirection="row"
                              justifyContent={"end"}
                            >
                              <Stack>
                                <Box>
                                  <Stack direction={"row"} justifyContent="end">
                                    <Avatar
                                      src={item?.senderKyc?.aadharPhotoLink}
                                      sx={{ height: 30, width: 30 }}
                                    />
                                  </Stack>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    backgroundColor: "#F4F6F8",
                                    px: 1,
                                    py: 0.5,
                                    mx: 1,
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Typography12
                                    text={item.message}
                                  ></Typography12>
                                  <Stack mt={1.5} ml={1}>
                                    <Typography12
                                      fontSize={8}
                                      text={getCurrentTime()}
                                    ></Typography12>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                          )
                        )}
                        {/* <div ref={messagesEndRef} /> */}
                      </Stack>
                    </Box>

                    <Divider />
                  </Box>

                  <Stack flexDirection="row" gap={2}>
                    <TextField
                      value={message}
                      placeholder="type message here..."
                      onChange={(event) => {
                        setMessage(event.target.value);
                      }}
                      fullWidth
                      inputProps={{
                        disableUnderline: true,
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          sendMessage();
                        }
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      // sx={{ borderBottom: 0 }}
                    ></TextField>
                    <Stack mt={1}>
                      <IoMdSend size={30} onClick={sendMessage} />
                    </Stack>
                  </Stack>
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box sx={{ flex: "3" }}>
                  <Box display={"flex"} justifyContent={"center"} mt={4}>
                    <Avatar
                      src={
                        (orderDetails &&
                          orderDetails.target &&
                          orderDetails.target.aadharPhotoLink) ||
                        ""
                      }
                      sx={{ height: "100px", width: "100px" }}
                    />
                  </Box>
                  <Stack textAlign={"center"} my={2} gap={0.8}>
                    <Typography>
                      Order ID:{" "}
                      {(orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.orderID) ||
                        "N/A"}
                    </Typography>
                    <Typography
                      fontSize={12}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#212B36"}
                    >
                      {(orderDetails &&
                        orderDetails.order &&
                        splitDateConversion(orderDetails.order.created_at)) ||
                        "N/A"}
                    </Typography>
                    <Typography
                      fontSize={12}
                      fontFamily={"Public Sans"}
                      fontWeight={500}
                      fontStyle={"normal"}
                      color={"#637381"}
                    >
                      {(orderDetails &&
                        orderDetails.target &&
                        orderDetails.target.targetType) ||
                        "N/A"}
                    </Typography>
                  </Stack>
                  <Divider sx={{ pt: 2 }} />
                  <Box mt={1}>
                    <Typography
                      fontSize={13}
                      fontFamily={"Public Sans"}
                      fontWeight={600}
                      fontStyle={"normal"}
                      color={"#637381"}
                      textAlign={"center"}
                    >
                      CUSTOMER OFFER
                    </Typography>
                  </Box>
                  <Stack spacing={0.2} my={2} mx={2}>
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#919EAB"}
                    >
                      Order Tittle
                    </Typography>{" "}
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#212B36"}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.title}
                    </Typography>
                  </Stack>
                  <Stack spacing={0.2} my={1.5} mx={2}>
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#919EAB"}
                    >
                      Proposal Validity
                    </Typography>{" "}
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#212B36"}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        splitDateConversion(
                          orderDetails.order.paymentSettlementDate
                        )}
                    </Typography>
                  </Stack>
                  <Stack spacing={0.2} my={1.5} mx={2}>
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#919EAB"}
                    >
                      Amount
                    </Typography>{" "}
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#212B36"}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        numberFormat(orderDetails.order.orderAmount)}
                    </Typography>
                  </Stack>
                  <Stack spacing={0.2} my={1.5} mx={2}>
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#919EAB"}
                    >
                      Agreed days
                    </Typography>{" "}
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#212B36"}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.paymentDays}
                    </Typography>
                  </Stack>
                  <Stack spacing={0.2} my={1.5} mx={2}>
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#919EAB"}
                    >
                      Order Type
                    </Typography>{" "}
                    <Typography
                      fontSize={11}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                      fontStyle={"normal"}
                      color={"#212B36"}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        formatString(orderDetails.order.orderType)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Main;
