import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  TablePagination,
  Tabs,
  Tab,
  Grid,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../../components/businessNetwok/typography/TypographyText";
import { useSelector } from "react-redux";
import {
  Typography12,
  Typography20,
} from "../../../components/typography/Typography";
import PaymentListTable from "./PaymentListTable";
import { accurateDateFormat } from "../../../utils/Validations";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ApiService from "../../../api/api";
import SimpleLoader from "../../../components/SimpleLoader";
import { formatDateString } from "../../../constant/generalMixing";

const Orders = () => {
  let newToken: any = useSelector((state: any) =>
    state.user.Token ? state.user.Token : null
  );
  const [open, setopen] = useState(true);
  const [orderTableOpen, setorderTableOpen] = useState(true);
  const [apiStep1, setApiStep1] = useState(false);
  const [apiStep2, setApiStep2] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabValue, setTabValue] = useState("buyer");
  const [NotFound, setNotFound] = useState(false);
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("all");
  // userlist for save data from all users
  const [OrderList, setOrderLists] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const [OrderCount, setOrderCount] = useState<any>({});
  const [startDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  //// Order & PR List Table- - - - - - - - - - - - - - - - -
  const getpaymentList = async () => {
    setApiStep2(true);
    setNotFound(false);
    let params = {
      page: page > 0 ? page : 1,
      rowsLimitInPage: rowsPerPage ? rowsPerPage : 10,
      paymentIdentifier: tabValue,
      status: userKycStatusValue,
      startDate: startDate,
      endDate: EndDate,
      search: searchValue,
    };
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      "/checkerRoutes/getallpaymentofchecker",
      params
    );
    if (responseData.status == 200) {
      let list = responseData.data.result;
      let count = responseData.data.orderCounts;
      setOrderCount(count);
      if (list.length > 0) {
        setOrderLists(list);
      } else {
        setOrderLists([]);
      }
      setApiStep2(false);
      setorderTableOpen(false);
    } else {
      setOrderLists([]);
      setOrderCount(0);
      setorderTableOpen(false);
      setNotFound(true);
      setopen(false);
      setApiStep2(false);
    }
  };

  ////- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect(() => {
    if ((startDate && EndDate) || searchValue) {
      getpaymentList();
    }
  }, [startDate, EndDate, searchValue, apiStep1]);

  useEffect(() => {
    getpaymentList();
  }, [tabValue, userKycStatusValue, apiStep1]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
    setPage(0);
  };

  const handleOnChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setTabValue(newValue);
    // getpaymentList();
  };
  const resetSeachValue = () => {
    setStartDate(null);
    setEndDate(null);
    setsearchValue("");
  };
  useEffect(() => {
    getpaymentList();
  }, [startDate, EndDate]);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    borderRadius: "18px",
    color: theme.palette.text.secondary,
    gap: 1,
  }));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box>
      {" "}
      <Stack>
        <Stack mt={4} mx={4}>
          <Typography20 text="Order Details"></Typography20>
        </Stack>
        <Box borderRadius={"16px"} mx={2} mt={1}>
          {" "}
          <Tabs
            value={tabValue}
            onChange={handleOnChangeTabValue}
            sx={{
              "& .MuiTabs-indicator": {
                maxWidth: "60px",
                mx: 2,
                backgroundColor: "rgba(48, 57, 129, 1)",
                textColor: "rgba(33, 43, 54, 1)",
              },

              "& .MuiTab-textColorPrimary": {
                color: "black",
              },
              "& .MuiTab-textColorSecondary": {
                color: "red",
              },
            }}
          >
            <Tab
              label="Buying"
              value="buyer"
              sx={{
                mx: 0,
                px: 0,
                textDecoration: "none",
                textTransform: "none",
                "&.MuiTab-labelIcon": {
                  fontSize: "12px",
                  fontWeight: "600",
                  fontFamily: "Public Sans",
                  textTransform: "none",
                },
              }}
            />
            <Tab
              label="Selling"
              value={"seller"}
              sx={{
                ml: -2,
                textTransform: "none",
                "&.MuiTab-labelIcon": {
                  fontSize: "12px",
                  fontWeight: "600",
                  fontFamily: "Public Sans",
                  textTransform: "none",
                },
              }}
            />
          </Tabs>
          {orderTableOpen ? (
            <Box mx={2} my={1} mb={2}>
              <Grid item xs={12}>
                <Item
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    py: 15,
                    boxShadow:
                      "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                  }}
                >
                  <CircularProgress
                    color="inherit"
                    sx={{ color: "grey" }}
                    size={30}
                  />
                </Item>
              </Grid>
            </Box>
          ) : (
            <Box
              boxShadow={
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
              }
              borderRadius={"16px"}
              mx={2}
              my={2}
              sx={{ backgroundColor: "white" }}
            >
              <Tabs
                value={userKycStatusValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary"
                sx={{
                  px: 2,
                  "& .MuiTabs-indicator": {
                    ml: 2,
                    // maxWidth: "90px",
                  },
                }}
              >
                <Tab
                  value="all"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={OrderCount?.all || 0}
                        chipColor={"rgba(0, 184, 217, 0.16)"}
                        chipFontColor={"#006C9C"}
                      />
                      <TypographyText fontSize={14} text={"All"} />
                    </Stack>
                  }
                ></Tab>
                <Tab
                  value="approved by checker"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={OrderCount?.approved || 0}
                        chipColor={"rgba(145, 158, 171, 0.16)"}
                        chipFontColor={"rgba(33, 43, 54, 1)"}
                      />
                      <TypographyText text={"Approved"} fontSize={14} />
                    </Stack>
                  }
                ></Tab>
                <Tab
                  value="active"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={OrderCount?.active || 0}
                        chipColor={"rgba(54, 179, 126, 0.16)"}
                        chipFontColor={"green"}
                      />
                      <TypographyText text={"Active"} fontSize={14} />
                    </Stack>
                  }
                ></Tab>
                <Tab
                  value="cancelled"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={OrderCount?.cancelled || 0}
                        chipColor={"rgba(255, 86, 48, 0.16)"}
                        chipFontColor={"rgba(183, 29, 24, 1)"}
                      />
                      <TypographyText text={"Cancelled"} fontSize={14} />
                    </Stack>
                  }
                ></Tab>
                <Tab
                  value="rejected"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={OrderCount?.rejected || 0}
                        chipColor={"rgba(255, 86, 48, 0.16)"}
                        chipFontColor={"rgba(183, 29, 24, 1)"}
                      />
                      <TypographyText text={"Rejected"} fontSize={14} />
                    </Stack>
                  }
                ></Tab>
                <Tab
                  value="expired"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={OrderCount?.expired || 0}
                        chipColor={"rgba(255, 86, 48, 0.16)"}
                        chipFontColor={"rgba(183, 29, 24, 1)"}
                      />
                      <TypographyText text={"Expired"} fontSize={14} />
                    </Stack>
                  }
                ></Tab>
                <Tab
                  value="completed"
                  sx={{ textTransform: "none", px: 2 }}
                  label={
                    <Stack display={"flex"} direction={"row"} spacing={1.5}>
                      <Chips
                        count={
                          OrderCount && OrderCount.completed
                            ? OrderCount.completed
                            : 0
                        }
                        chipColor={"rgba(54, 179, 126, 0.16)"}
                        chipFontColor={"green"}
                      />
                      <TypographyText text={"Complete"} fontSize={14} />
                    </Stack>
                  }
                ></Tab>
              </Tabs>
              <Divider sx={{ color: "rgba(145, 158, 171, 0.24)" }}></Divider>
              <Stack
                sx={{ mx: 4, py: 1 }}
                display={"flex"}
                direction={"row"}
                spacing={0.5}
              >
                {" "}
                <Box>
                  <FormControl>
                    <Stack flexDirection={"row"} gap={2} py={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* Start Date Picker */}
                        <DatePicker
                          label="Start Date"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={(value) => setStartDate(value)}
                          sx={{
                            style: {
                              borderRadius: "8px",
                            },
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                "& .MuiInputBase-root": {
                                  height: 50,
                                  borderRadius: "8px",
                                },
                                "& .MuiFormLabel-root": {
                                  color: "#919EAB",
                                  fontFamily: "Public Sans",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  mt: 0.2,
                                },
                              },
                              style: {
                                borderRadius: "16px",
                                borderColor: "#919EAB",
                                width: "100%",
                                maxWidth: "50%",
                                margin: 0,
                              },
                            },
                          }}
                        />

                        {/* End Date Picker */}
                        <DatePicker
                          label="End Date"
                          format="DD/MM/YYYY"
                          value={EndDate}
                          minDate={dayjs(startDate)}
                          disabled={!startDate}
                          onChange={(value: any) => setEndDate(value)}
                          sx={{
                            style: {
                              borderRadius: "8px",
                            },
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                "& .MuiInputBase-root": {
                                  height: 50,
                                  borderRadius: "8px",
                                },
                                "& .MuiFormLabel-root": {
                                  color: "#919EAB",
                                  fontFamily: "Public Sans",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  mt: 0.2,
                                },
                              },
                              style: {
                                borderRadius: "16px",
                                borderColor: "#919EAB",
                                width: "100%",
                                maxWidth: "50%",
                                margin: 0,
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </FormControl>
                </Box>
                <TextField
                  fullWidth
                  onChange={(e) => setsearchValue(e.target.value)}
                  placeholder="Search by Business Name"
                  value={searchValue}
                  InputProps={{
                    sx: {
                      my: 1,
                    },
                    style: { borderRadius: "10px", height: "50px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <Button
                    size="small"
                    onClick={resetSeachValue}
                    sx={{
                      color: "white",
                      mt: 1.2,
                      px: 1,
                      py: 1.5,
                      ml: 1,
                      borderRadius: "8px",
                      backgroundColor: "#303981",
                      textAlign: "right",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#303981",
                      },
                    }}
                  >
                    <Typography12 color={"white"} text={"Reset"}></Typography12>
                  </Button>
                </Box>
              </Stack>
              <Box>
                {apiStep2 ? (
                  <SimpleLoader />
                ) : (
                  <>
                    <PaymentListTable
                      props={OrderList}
                      PaymentSelectType={tabValue}
                      apiStep2={apiStep2}
                    />
                  </>
                )}
              </Box>
              <Box>
                <Divider></Divider>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"end"}
                >
                  <TablePagination
                    component="div"
                    count={OrderCount.all || 0}
                    page={page || 0}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage || 0}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>
              </Box>
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Orders;
