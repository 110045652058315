import {
  Button,
  Box,
  Stack,
  TextField,
  Container,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import RemainingIcon from "../../assets/ic_info.svg";
import ProgressBarCircular from "../../components/ProgressBarCircular";
import { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import ImportantNoteSvg from "../../assets/shape.svg";
import { changeTextGstProfile } from "../../utils/AadharProfileTextArrange";
import { LoadingButton } from "@mui/lab";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { characterUpperCase } from "../../utils/Validations";
import SimpleLoader from "../../components/Loader";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import ApiService from "../../api/api";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { BusinessType, State } from "../../constant/constant";
import { MenuProps } from "../../constant/generalMixing";

const CreateUserGstProfile = () => {
  // Token Access
  const newToken = useSelector((state: any) => state.user.Token);

  const [GstErrorMsgDynamic, setGstErrorMsgDynamic] = useState("");
  const [GstManualSuccessMsg, setGstManualSuccessMsg] = useState(false);
  const [GstManualErrorMsg, setGstManualErrorMsg] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [gstVerifyLoadingBtnDisabled, setgstVerifyLoadingBtnDisabled] =
    useState(false);
  const [ErrorMsgGstFetch, setErrorMsgGstFetch] = useState(false);
  const [DisableGstNumberField, setDisableGstNumberField] = useState(false);
  const [RemainingCount, setRemainingCount] = useState();
  const [progressPercent, setProgressPercent] = useState<any>(0);
  const [createGstForAlreadyExist, setcreateGstForAlreadyExist] =
    useState(false);
  const [ErrorMsgGstFetchRemainingCount, setErrorMsgGstFetchRemainingCount] =
    useState(false);
  const [ManualUserGstDetails, setManualUserGstDetails] = useState<any>({});
  const navigate = useNavigate();
  //Dialog Box Code
  const [open, setOpen] = useState(false);
  const [openManulGst, setOpenManualGst] = useState(false);

  const handleClickDialogOpen = () => {
    setOpen(true);
  };
  const handleClickDialogClose = () => {
    setOpen(false);
  };
  const handleClickOpenManualGst = () => {
    setOpenManualGst(true);
  };
  const handleClickCloseManualGst = () => {
    setOpenManualGst(false);
  };
  const handleClickGstRegister = async () => {
    setGstCompletedLoader(true);
    setcreateGstForAlreadyExist(false);

    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      "/userkyc/savegstdetail",
      GstFetchDetails
    );
    if (responseData.status === 200) {
      localStorage.setItem("userRole", responseData.data.UserRole);
      setProgressPercent(30);
      setGstNumber("");
      setVerifyBtnDisabled(false);
      setGstVerifyCheck(false);
      setGstFetchDetails({});
      setOpen(false);
      setGstCompletedLoader(false);
      SuccessMsg("Your GST document uploaded successfully");
      navigate("/auth/upload-files");
    } else {
      setGstCompletedLoader(false);
      ErrorMsg("Something went wrong, Please try again");
      setcreateGstForAlreadyExist(true);
    }
  };

  const handleOnChangeGstNumber = (e: any) => {
    const TrimGst = e.target.value ? e.target.value.trim() : "";
    setErrorMsgGstFetchRemainingCount(false);
    setGstNumber(TrimGst);

    if (e.target.value && TrimGst.length === 15) {
      setErrorMsgGstFetch(false);
      setVerifyBtnDisabled(false);
    } else {
      setErrorMsgGstFetch(false);
      setVerifyBtnDisabled(true);
    }
  };
  const handleRefreshGstDetails = () => {
    setDisableGstNumberField(false);
    setGstNumber("");
    setVerifyBtnDisabled(true);
    setGstVerifyCheck(false);
    setGstFetchDetails({});
  };
  const [GstNumber, setGstNumber] = useState("");
  const [VerifyBtnDisabled, setVerifyBtnDisabled] = useState(true);
  const [GstVerifyCheck, setGstVerifyCheck] = useState(false);
  const [GstNumberConfirmationRadio, setGstNumberConfirmationRadio] =
    useState(true);
  const [GstCompletedLoader, setGstCompletedLoader] = useState(false);

  const [GstFetchDetails, setGstFetchDetails] = useState<any>([]);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleGstDetails = async (e: any) => {
    e.preventDefault();
    setVerifyBtnDisabled(true);
    setgstVerifyLoadingBtnDisabled(true);
    if (GstNumberConfirmationRadio === true && GstNumber !== "") {
      const data = new FormData(e.currentTarget);

      const body = {
        GSTNumber: data.get("userGstNumber"),
      };
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.post(
        "/userkyc/getgstdetail",
        body
      );
      if (responseData.status === 200) {
        const getUserLogin = responseData.data;
        if (responseData.status === 200) {
          setgstVerifyLoadingBtnDisabled(false);
          setRemainingCount(getUserLogin.result.leftAttempt);
          setGstFetchDetails(getUserLogin.result.result);
          setGstVerifyCheck(true);
          setDisableGstNumberField(true);
        }
      } else {
        setErrorMsgGstFetch(true);
        setgstVerifyLoadingBtnDisabled(false);
        setGstErrorMsgDynamic(
          responseData?.response?.data?.message?.message ||
            responseData?.response?.data?.message ||
            "Something went wrong, Please try again"
        );
      }
    }
  };

  const handleChangeSetManualGstdetails = (event: any) => {
    const { name, value } = event.target;
    setManualUserGstDetails((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  const saveManualGstDetails = async () => {
    setGstManualErrorMsg(false);
    setGstManualSuccessMsg(false);
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      "/userkyc/savegstdetail",
      ManualUserGstDetails
    );
    if (responseData.status === 200) {
      localStorage.setItem("userRole", responseData.data.UserRole);
      setGstManualSuccessMsg(true);
      setOpenManualGst(false);
      setOpen(false);
      navigate("/auth/upload-files");
    } else {
      setGstManualErrorMsg(true);
    }
  };

  const Taxpayer_Type = ["Regular", "Composite"];

  useEffect(() => {
    if (newToken) {
      setisLoggedIn(true);
    } else {
      setisLoggedIn(false);
      navigate("/auth/login");
    }
  }, [isLoggedIn]);
  const handleonChangeForDate = (value: any) => {
    setManualUserGstDetails((prevState: any) => ({
      ...prevState,
      Date_of_Registration: value,
      isGSTDetailSaveManually: true,
    }));
  };

  return (
    <>
      {/* App Title For GST Page */}
      <Helmet>
        {" "}
        <title> Let's Get Started with Your Information | GST</title>
      </Helmet>
      {isLoggedIn ? (
        <Box margin={1} sx={{ flexGrow: 1 }}>
          <Box textAlign={"center"}>
            <Stack sx={{ px: "100px" }}>
              <CustomizedSteppers activeStep={0} />
            </Stack>
          </Box>
          <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
            <Box
              borderRadius={"12px"}
              boxShadow={
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
              }
              justifyContent={"center"}
              sx={{
                display: "flex",
                flexDirection: "column",
                px: 5,
                py: 2,
                pb: 4,
              }}
            >
              <Grid
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: "column", sm: "row" }}
                sx={{ fontSize: "12px" }}
              >
                <Grid sx={{ order: { xs: 12, sm: 2 } }}>
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={19}
                    fontWeight={700}
                    lineHeight={"30px"}
                    color={"#212B36"}
                  >
                    Create Your Business Profile
                  </Typography>
                </Grid>
                <Grid
                  container
                  columnSpacing={1}
                  sx={{ order: { xs: 12, sm: 2 } }}
                >
                  {/* <Grid>
                    <ProgressBarCircular percentage={progressPercent} />
                  </Grid> */}
                </Grid>
              </Grid>
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    mt: 0.5,
                    color: "#556486",
                    fontWeight: 600,
                    fontFamily: "Public Sans",
                    fontStyle: "normal",
                  }}
                >
                  {" "}
                  Do you have Gst Number?
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row
                    onChange={(e) =>
                      setGstNumberConfirmationRadio(!GstNumberConfirmationRadio)
                    }
                    defaultValue={true}
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label="Yes"
                      value={true}
                      sx={{
                        "& .MuiFormControlLabel": {
                          label: {
                            fontSize: 4,
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      sx={{ ml: 2 }}
                      value={false}
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>

                {/* Gst Verify Check for Button and Success Msg showing after successfull gst verify check */}
                {GstNumberConfirmationRadio ? (
                  <>
                    <Box
                      sx={{ my: 2 }}
                      component="form"
                      onSubmit={handleGstDetails}
                    >
                      <Stack direction={"row"}>
                        <TextField
                          disabled={DisableGstNumberField}
                          label="Enter Your GST Number"
                          size="small"
                          type="text"
                          name="userGstNumber"
                          value={characterUpperCase(GstNumber)}
                          onChange={handleOnChangeGstNumber}
                          InputProps={{
                            style: {
                              borderRadius: "8px",
                              borderColor: "#919EAB",
                            },
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              height: 40,
                              minWidth: "23vw",
                            },
                            "& .MuiFormLabel-root": {
                              color: "#919EAB",
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              fontSize: "14px",
                              Height: "20px",
                            },
                          }}
                        />{" "}
                        {!GstVerifyCheck ? (
                          <>
                            <LoadingButton
                              loading={gstVerifyLoadingBtnDisabled}
                              size="small"
                              disabled={VerifyBtnDisabled}
                              sx={{ py: 0.8, px: 2.5, mt: 0.2 }}
                              style={{
                                color: VerifyBtnDisabled
                                  ? "rgba(145, 158, 171, 0.80)"
                                  : "white",
                                marginLeft: 4,
                                textTransform: "none",
                                backgroundColor: VerifyBtnDisabled
                                  ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                  : "#00AB55",
                                borderRadius: "8px",
                                fontSize: "13px",
                                fontWeight: "700",
                              }}
                              type="submit"
                            >
                              Verify
                            </LoadingButton>
                          </>
                        ) : (
                          <>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ ml: "20px", mt: 1 }}
                            >
                              <>
                                <DoneIcon
                                  style={{ fontSize: "15px", color: "#36B37E" }}
                                />{" "}
                                <Typography
                                  fontWeight={600}
                                  fontSize={11}
                                  fontFamily={"Public Sans"}
                                  color={"#36B37E"}
                                >
                                  {" "}
                                  Business Details Fetched SuccessFully
                                </Typography>
                              </>
                            </Stack>
                          </>
                        )}
                      </Stack>
                      {ErrorMsgGstFetch ? (
                        <Typography
                          fontSize={12}
                          color={"red"}
                          fontStyle={"normal"}
                          fontFamily={"Public Sans"}
                          fontWeight={700}
                          sx={{ mt: 0.5 }}
                        >
                          {" "}
                          {GstErrorMsgDynamic}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {ErrorMsgGstFetchRemainingCount ? (
                        <Typography
                          fontSize={12}
                          color={"red"}
                          fontStyle={"normal"}
                          fontFamily={"Public Sans"}
                          fontWeight={700}
                        >
                          {" "}
                          Your attempt is exceed, Please contact to admin !
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                    {GstVerifyCheck ? (
                      <>
                        <Stack display={"flex"} direction={"row"} spacing={0.5}>
                          <img
                            src={RemainingIcon}
                            style={{ height: "16px", width: "16px" }}
                            alt=""
                          />
                          <Typography
                            fontSize={12}
                            color={"var(--text-light-secondary, #637381)"}
                            fontStyle={"normal"}
                            fontFamily={"Public Sans"}
                            fontWeight={400}
                          >
                            {" "}
                            Remaining attempt : {RemainingCount}
                          </Typography>
                        </Stack>
                        <Box>
                          <Typography
                            component="div"
                            sx={{ mt: 1 }}
                            fontFamily={"Public Sans"}
                            fontSize={15}
                            fontWeight={700}
                            lineHeight={"30px"}
                            color={"#556486"}
                          >
                            Your Business Details as per records
                          </Typography>
                          <Grid
                            container
                            sx={{
                              m: 0,
                              mt: 0.5,
                              ml: -0.5,
                              p: 0,
                              "& .MuiPaper-root": {
                                padding: "0px",
                              },
                            }}
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                          >
                            {/* {Array.from(Array(7)).map((_, index) => ( */}
                            {GstFetchDetails &&
                              Object.keys(GstFetchDetails).map(
                                (name: any, index: any) => (
                                  <Grid
                                    style={{
                                      padding: "8px",
                                    }}
                                    sx={{ my: 0, p: 0 }}
                                    xs={2}
                                    sm={4}
                                    md={4}
                                    key={index}
                                  >
                                    <Item>
                                      <Typography
                                        sx={{ m: 0, p: 0 }}
                                        fontFamily={"Public Sans"}
                                        fontSize={11}
                                        color={"#919EAB"}
                                      >
                                        {changeTextGstProfile(name)}
                                      </Typography>
                                      <Typography
                                        fontSize={"14px"}
                                        fontStyle={"normal"}
                                        fontFamily={"Public Sans"}
                                        color={"#212B36"}
                                        fontWeight={600}
                                        sx={{ m: 0, p: 0 }}
                                      >
                                        {GstFetchDetails[name]}
                                      </Typography>
                                    </Item>
                                  </Grid>
                                )
                              )}
                          </Grid>
                          <Stack spacing={2} direction={"row"} sx={{ mt: 2 }}>
                            <LoadingButton
                              type="submit"
                              sx={{ ml: 0.5, px: 2 }}
                              style={{
                                fontFamily: "Public Sans",
                                color: "white",
                                maxWidth: "250px",
                                width: "auto",
                                fontSize: "12px",
                                backgroundColor: "#303981",
                                textTransform: "none",
                                borderRadius: "8px",
                                fontWeight: "700",
                              }}
                              onClick={handleClickDialogOpen}
                            >
                              Yes, these are my business details
                            </LoadingButton>
                            <Button
                              type="submit"
                              sx={{ ml: 0.5, px: 2 }}
                              style={{
                                fontFamily: "Public Sans",
                                color: "#303981",
                                maxWidth: "250px",
                                width: "auto",
                                border: "1px solid #303981",
                                fontSize: "12px",
                                textTransform: "none",
                                borderRadius: "8px",
                                fontWeight: "700",
                              }}
                              onClick={handleRefreshGstDetails}
                            >
                              No, try with another GST Number
                            </Button>
                          </Stack>
                          <Dialog
                            open={open}
                            aria-labelledby="responsive-dialog-title"
                            style={{ borderRadius: "10px" }}
                          >
                            <DialogTitle
                              id="responsive-dialog-title"
                              sx={{ px: 4, maxWidth: "500px" }}
                            >
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={2}
                              >
                                <img
                                  src={ImportantNoteSvg}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                  }}
                                  alt=""
                                />
                                <Typography
                                  component="div"
                                  fontFamily={"Public Sans"}
                                  fontSize={"20px"}
                                  fontWeight={700}
                                  lineHeight={"28px"}
                                  color={"#212B36"}
                                >
                                  Important Note
                                </Typography>
                              </Stack>
                            </DialogTitle>
                            <DialogContent sx={{ px: 4, maxWidth: "430px" }}>
                              <DialogContentText
                                color={"#454F5B"}
                                textAlign={"start"}
                                justifyContent={"center"}
                                alignSelf={"stretch"}
                                fontFamily={"Public Sans"}
                                fontSize={"16px"}
                                fontWeight={400}
                              >
                                Once you confirm your business details, It
                                cannot be changed. Are you sure you want to
                                proceed with these business details?
                              </DialogContentText>
                              {createGstForAlreadyExist ? (
                                <Typography
                                  color={"red"}
                                  fontWeight={600}
                                  fontFamily={"Public Sans"}
                                >
                                  {" "}
                                  Something went wrong, Please try again !{" "}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </DialogContent>
                            <DialogActions
                              sx={{
                                justifyContent: "center",
                                textAlign: "center",
                                px: 8,
                                mb: 2,
                                // maxWidth: "430px",
                              }}
                            >
                              <Button
                                type="submit"
                                sx={{ ml: 0.5, px: 2 }}
                                style={{
                                  fontFamily: "Public Sans",
                                  color: "white",
                                  width: "auto",
                                  fontSize: "14px",
                                  backgroundColor: "#303981",
                                  textTransform: "none",
                                  borderRadius: "8px",
                                  fontWeight: "700",
                                }}
                                onClick={handleClickGstRegister}
                              >
                                Yes, Continue
                              </Button>
                              <Button
                                type="submit"
                                sx={{ ml: 0.5, px: 1, py: 0.6 }}
                                style={{
                                  fontFamily: "Public Sans",
                                  color: "Black",
                                  width: "auto",
                                  border: "1px solid ",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  borderRadius: "8px",
                                  fontWeight: "700",
                                }}
                                onClick={handleClickDialogClose}
                              >
                                No, I want to Correct the details
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <SimpleLoader props={GstCompletedLoader} />
                        </Box>
                      </>
                    ) : (
                      <>
                        <div style={{ minHeight: "300px" }}></div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Box
                      sx={{ mt: 2 }}
                      component={"form"}

                      // onSubmit={handleGstFromDetails}
                    >
                      <Typography
                        component="div"
                        fontFamily={"Public Sans"}
                        fontSize={14}
                        fontWeight={700}
                        lineHeight={"30px"}
                        color={"#556486"}
                      >
                        Enter Your Business Details
                      </Typography>
                      <Grid container sx={{ mt: 1, ml: 0 }} spacing={1}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: "10px",
                            my: 2,
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                mt: -1,
                              }}
                              htmlFor="grouped-native-select"
                            >
                              Business Type
                            </InputLabel>
                            <Select
                              value={
                                ManualUserGstDetails
                                  ? ManualUserGstDetails.Constitution_of_Business
                                  : ""
                              }
                              onChange={handleChangeSetManualGstdetails}
                              name="Constitution_of_Business"
                              defaultValue={""}
                              fullWidth
                              sx={{
                                border: "none",
                                borderRadius: "8px",
                                height: 40,
                              }}
                              input={
                                <OutlinedInput
                                  sx={{
                                    borderRadius: "8px",
                                    "& .MuiFormLabel-root": {
                                      fontSize: "10px",
                                      mb: 4,
                                    },
                                  }}
                                  label="Business Type"
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {BusinessType.map((name, index) => (
                                <MenuItem key={name} value={name}>
                                  <Typography
                                    fontFamily={"Public Sans"}
                                    fontSize={"14px"}
                                    fontWeight={400}
                                  >
                                    {name}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                mt: -1,
                              }}
                              htmlFor="grouped-native-select"
                            >
                              Taxpayer Type
                            </InputLabel>
                            <Select
                              defaultValue={""}
                              // value={GstSelectDetails.Taxpayer_Type}
                              value={
                                ManualUserGstDetails
                                  ? ManualUserGstDetails.Taxpayer_Type
                                  : ""
                              }
                              onChange={handleChangeSetManualGstdetails}
                              name="Taxpayer_Type"
                              sx={{
                                border: "none",
                                borderRadius: "8px",
                                height: 40,
                              }}
                              input={
                                <OutlinedInput
                                  sx={{
                                    borderRadius: "8px",
                                    "& .MuiFormLabel-root": {
                                      fontSize: "10px",
                                      mb: 4,
                                    },
                                  }}
                                  label="Taxpayer Type"
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {Taxpayer_Type.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Typography
                                    fontFamily={"Public Sans"}
                                    fontSize={"14px"}
                                  >
                                    {name}
                                  </Typography>{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>{" "}
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: "10px",
                            my: 2,
                          }}
                        >
                          <TextField
                            fullWidth
                            value={
                              ManualUserGstDetails
                                ? ManualUserGstDetails?.Business_PAN
                                : ""
                            }
                            onChange={handleChangeSetManualGstdetails}
                            defaultValue={""}
                            label="Business PAN Number"
                            size="small"
                            name="Business_PAN"
                            type="Business_PAN"
                            id="Business_PAN"
                            // value={GstSelectDetails.Business_PAN}

                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                borderColor: "#919EAB",
                                textTransform: "uppercase",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                minWidth: "98%",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                Height: "20px",
                              },
                            }}
                          />{" "}
                          <TextField
                            fullWidth
                            value={
                              ManualUserGstDetails
                                ? ManualUserGstDetails.Legal_Name_of_Business
                                : ""
                            }
                            defaultValue={""}
                            onChange={handleChangeSetManualGstdetails}
                            label="Business Name"
                            size="small"
                            name="Legal_Name_of_Business"
                            // value={GstSelectDetails.Legal_Name_of_Business}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                borderColor: "#919EAB",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                // minWidth: "98%",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16px",
                                Height: "20px",
                              },
                            }}
                          />
                        </Stack>{" "}
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: "10px",
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              sx={{
                                width: "100%",
                                overflow: "hidden",
                              }}
                              components={["DatePicker"]}
                            >
                              <DatePicker
                                label="Registration Date"
                                format="DD/MM/YYYY"
                                sx={{
                                  root: {
                                    color: "#919EAB",
                                    backgroundColor: "transparent",
                                    fontFamily: "Public Sans",
                                    fontWeight: "800",
                                    fontSize: "16",
                                    minWidth: "100%",
                                  },
                                }}
                                onChange={(value: any) =>
                                  handleonChangeForDate(
                                    moment(value ? value.$d : "").format(
                                      "DD/MM/YYYY"
                                    )
                                  )
                                }
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    sx: {
                                      borderColor: "#919EAB",
                                      width: "100%",
                                      borderRadius: "10px",
                                    },
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>{" "}
                          <FormControl fullWidth sx={{ pt: "8px" }}>
                            <InputLabel
                              sx={{
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                              }}
                              htmlFor="grouped-native-select"
                            >
                              State
                            </InputLabel>
                            <Select
                              // value={GstSelectDetails.State}
                              size="small"
                              value={
                                ManualUserGstDetails
                                  ? ManualUserGstDetails.State
                                  : ""
                              }
                              onChange={handleChangeSetManualGstdetails}
                              name="State"
                              sx={{
                                border: "none",
                                borderRadius: "8px",
                              }}
                              input={
                                <OutlinedInput
                                  sx={{
                                    borderRadius: "8px",
                                    "& .MuiFormLabel-root": {
                                      fontSize: "10px",
                                    },
                                  }}
                                  label="Constitution of Business"
                                />
                              }
                              MenuProps={MenuProps}
                            >
                              {State.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Typography
                                    fontFamily={"Public Sans"}
                                    fontSize={"14px"}
                                  >
                                    {name}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>{" "}
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: "10px",
                            my: 2,
                          }}
                        >
                          <TextField
                            label="Trade Name"
                            size="small"
                            name="Trade_Name"
                            fullWidth
                            // value={GstSelectDetails.Trade_Name}
                            value={
                              ManualUserGstDetails
                                ? ManualUserGstDetails.Trade_Name
                                : ""
                            }
                            onChange={handleChangeSetManualGstdetails}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                borderColor: "#919EAB",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                Height: "20px",
                              },
                            }}
                          />{" "}
                          <TextField
                            label="Business Place"
                            size="small"
                            name="Place_of_Business"
                            fullWidth
                            value={
                              ManualUserGstDetails
                                ? ManualUserGstDetails.Place_of_Business
                                : ""
                            }
                            onChange={handleChangeSetManualGstdetails}
                            // value={GstSelectDetails.Trade_Name}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                borderColor: "#919EAB",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                minWidth: "98%",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                Height: "20px",
                              },
                            }}
                          />
                        </Stack>{" "}
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            gap: "10px",
                            my: 2,
                          }}
                        >
                          <TextField
                            label="Nature of Place of Business"
                            fullWidth
                            value={
                              ManualUserGstDetails
                                ? ManualUserGstDetails.Nature_of_Place_of_Business
                                : ""
                            }
                            onChange={handleChangeSetManualGstdetails}
                            size="small"
                            name="Nature_of_Place_of_Business"
                            // value={GstSelectDetails.Nature_of_Place_of_Business}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                borderColor: "#919EAB",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                minWidth: "98%",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                Height: "20px",
                              },
                            }}
                          />{" "}
                          <TextField
                            label="Business Activity"
                            size="small"
                            name="Nature_of_Business_Activity"
                            fullWidth
                            value={
                              ManualUserGstDetails
                                ? ManualUserGstDetails.Nature_of_Business_Activity
                                : ""
                            }
                            onChange={handleChangeSetManualGstdetails}
                            // value={GstSelectDetails.Nature_of_Place_of_Business}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                borderColor: "#919EAB",
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                minWidth: "98%",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "16",
                                Height: "20px",
                              },
                            }}
                          />
                        </Stack>{" "}
                      </Grid>
                      <Button
                        disabled={
                          !(
                            ManualUserGstDetails?.Constitution_of_Business &&
                            ManualUserGstDetails?.Taxpayer_Type &&
                            ManualUserGstDetails?.Business_PAN &&
                            ManualUserGstDetails?.Legal_Name_of_Business &&
                            ManualUserGstDetails?.State &&
                            ManualUserGstDetails?.Trade_Name &&
                            ManualUserGstDetails?.Place_of_Business &&
                            ManualUserGstDetails?.Nature_of_Place_of_Business &&
                            ManualUserGstDetails?.Nature_of_Business_Activity
                          )
                        }
                        sx={{ my: 4, ml: 0, px: 3 }}
                        style={{
                          fontFamily: "Public Sans",
                          color: "white",
                          fontSize: "13px",
                          backgroundColor: !(
                            ManualUserGstDetails?.Constitution_of_Business &&
                            ManualUserGstDetails?.Taxpayer_Type &&
                            ManualUserGstDetails?.Business_PAN &&
                            ManualUserGstDetails?.Legal_Name_of_Business &&
                            ManualUserGstDetails?.State &&
                            ManualUserGstDetails?.Trade_Name &&
                            ManualUserGstDetails?.Place_of_Business &&
                            ManualUserGstDetails?.Nature_of_Place_of_Business &&
                            ManualUserGstDetails?.Nature_of_Business_Activity
                          )
                            ? "#A1A1A1"
                            : "#303981",
                          textTransform: "none",
                          borderRadius: "8px",
                          fontWeight: "700",
                        }}
                        onClick={handleClickOpenManualGst}
                      >
                        Yes, these are my business details
                      </Button>
                    </Box>
                  </>
                )}
                <Dialog
                  open={openManulGst}
                  aria-labelledby="responsive-dialog-title"
                  style={{ borderRadius: "10px" }}
                >
                  <DialogTitle
                    id="responsive-dialog-title"
                    sx={{ px: 4, maxWidth: "500px" }}
                  >
                    <Stack display={"flex"} direction={"row"} spacing={2}>
                      <img
                        src={ImportantNoteSvg}
                        style={{
                          height: "24px",
                          width: "24px",
                        }}
                        alt=""
                      />
                      <Typography
                        component="div"
                        fontFamily={"Public Sans"}
                        fontSize={"20px"}
                        fontWeight={700}
                        lineHeight={"28px"}
                        color={"#212B36"}
                      >
                        Important Note
                      </Typography>
                    </Stack>
                  </DialogTitle>
                  <DialogContent sx={{ px: 4, maxWidth: "430px" }}>
                    <DialogContentText
                      color={"#454F5B"}
                      textAlign={"start"}
                      justifyContent={"center"}
                      alignSelf={"stretch"}
                      fontFamily={"Public Sans"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      Once you confirm your business details, It cannot be
                      changed. Are you sure you want to proceed with these
                      business details?
                    </DialogContentText>
                    <Stack>
                      {GstManualErrorMsg && !GstManualSuccessMsg ? (
                        <Typography
                          color={"red"}
                          fontWeight={600}
                          fontFamily={"Public Sans"}
                        >
                          {" "}
                          Something went wrong, Please try again !{" "}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {GstManualSuccessMsg && !GstManualErrorMsg ? (
                        <Typography
                          color={"green"}
                          fontWeight={600}
                          fontFamily={"Public Sans"}
                        >
                          {" "}
                          Information Successfully Created{" "}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </DialogContent>

                  <DialogActions
                    sx={{
                      justifyContent: "center",
                      textAlign: "center",
                      px: 8,
                      mb: 2,
                      // maxWidth: "430px",
                    }}
                  >
                    <Button
                      type="submit"
                      sx={{ ml: 0.5, px: 2 }}
                      style={{
                        fontFamily: "Public Sans",
                        color: "white",
                        width: "auto",
                        fontSize: "14px",
                        backgroundColor: "#303981",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontWeight: "700",
                      }}
                      onClick={saveManualGstDetails}
                    >
                      Yes, Continue
                    </Button>
                    <Button
                      type="submit"
                      sx={{ ml: 0.5, px: 1, py: 0.6 }}
                      style={{
                        fontFamily: "Public Sans",
                        color: "Black",
                        width: "auto",
                        border: "1px solid ",
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontWeight: "700",
                      }}
                      onClick={handleClickCloseManualGst}
                    >
                      No, I want to Correct the details
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default CreateUserGstProfile;
