import { Container, Box } from "@mui/material";
import ResistoreIcon from "../../assets/signup.jpg";
import Grid from "@mui/material/Grid";
import UserResistor from "./UserResistor";
import Header from "../../components/Header";
import {
  Typography12,
  Typography20,
} from "../../components/typography/Typography";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import {
  arrangeReceivableState,
  clearToken,
  loginUser,
} from "../../redux/slices/user";
import { useDispatch } from "react-redux";
import Footer from "../../components/Footer";

const Registor = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loginUser(false));
    dispatch(arrangeReceivableState(""));
    dispatch(clearToken(""));
  }, []);
  // For Title Page
  return (
    <>
      <Header />
      <Box sx={{ flexGrow: 1 }}>
        <Container>
          <Box
            justifyContent={"center"}
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Grid
              container
              spacing={5}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row", margin: "auto" },
              }} // Change direction based on screen size
            >
              <Grid
                item
                xs={12}
                md={6} // Full width on small screens, half on medium and up
                sx={{ display: "flex", justifyContent: "center" }} // Center the form
              >
                <Box
                  boxShadow={
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
                  }
                  borderRadius={2}
                  sx={{ maxWidth: 350, width: "100%" }}
                  px={4}
                  mt={1}
                  pt={1}
                >
                  <Stack
                    display={"flex"}
                    direction={"column"}
                    p={2}
                    spacing={-0.5}
                  >
                    <Typography20 text={"Register your business"} />
                    <Typography12
                      fontSize={12}
                      color={"#556486"}
                      text={
                        "Join AssuredPay's business network. Streamline your"
                      }
                    />
                    <Typography12
                      fontSize={12}
                      color={"#556486"}
                      text={
                        "payments, optimize receivables, and access financial solutions."
                      }
                    />
                  </Stack>
                  <Box
                    display="block"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      " & .MuiTextField-root": {
                        m: 0.5,
                        maxWidth: "38ch",
                        width: "100%",
                      },
                    }}
                  >
                    {/* User Registration page */}
                    <UserResistor />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={0}
                md={6} // Hide image on small screens
                sx={{
                  display: { xs: "none", md: "block" }, // Hide on xs screens
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={ResistoreIcon}
                  style={{
                    margin: "80px 0 0 0",
                    maxHeight: "250px",
                    maxWidth: "550px",
                    alignItems: "center",
                  }}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Registor;
