import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import { Card, TableContainer, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Box, Stack } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  Typography14,
  Typography16,
} from "../../../components/typography/Typography";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { numberFormat, splitDateConversion } from "../../../utils/Validations";

export default function AcceptPaymentInterestTable(props: any) {
  const orderDetails = props ? props.props : {};
  const [value, setValue] = React.useState("1");

  const TotalReceivables = () => {
    const totalRecivablesSum =
      orderDetails &&
      orderDetails.milestones &&
      Object.values(orderDetails.milestones)
        .flat()
        .reduce((acc, obj: any) => acc + obj.Total_Recievables, 0);
    let finalAmount = totalRecivablesSum ? totalRecivablesSum.toFixed(2) : 0;
    return finalAmount;
  };
  return (
    <>
      <Box
        sx={{
          px: 4,
          py: 1,
        }}
      >
        <>
          <Box
            // mx={3}
            boxShadow={
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
            }
            borderRadius={"16px"}
            sx={{
              backgroundColor: "rgba(48, 57, 129, 1)",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  borderRadius: "10px",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      px: 4,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontWeight={600}
                      fontFamily={fontFamilys.publicSans}
                      color={"white"}
                    >
                      Payment
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontWeight={600}
                      fontFamily={fontFamilys.publicSans}
                      color={"white"}
                    >
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontWeight={600}
                      fontFamily={fontFamilys.publicSans}
                      color={"white"}
                    >
                      Platform Fee
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      // px: 4,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontWeight={600}
                      fontFamily={fontFamilys.publicSans}
                      color={"white"}
                    >
                      Payment Date
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontWeight={600}
                      fontFamily={fontFamilys.publicSans}
                      color={"white"}
                    >
                      Total Receivables
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "white" }}>
                {orderDetails.milestones &&
                  orderDetails.milestones.map((item: any, j: number) => (
                    // <Tab label={`Milestone ${j + 1}`} value={`${j + 1}`} />
                    <>
                      <TableRow
                        sx={{
                          boxShadow:
                            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                          borderRadius: "16px",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            px: 4,

                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={500}
                          >
                            {item && item.Payment}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"green"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={500}
                          >
                            {numberFormat((item && item.Amount) || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"red"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={500}
                          >
                            {numberFormat((item && item.Platform_Fee) || 0)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {splitDateConversion(item && item.Payment_Date)}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            fontWeight={500}
                            color={"rgba(0, 123, 85, 1)"}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item && item.Total_Recievables > 0
                              ? item.Total_Recievables.toFixed(2)
                              : 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"end"}
        sx={{
          px: 4,
        }}
      >
        <Stack display={"flex"} justifyContent={"end"} textAlign={"end"}>
          <Typography14 color="#007B55" text="Total Receivables"></Typography14>
          <Typography16
            color="#007B55"
            text={TotalReceivables() ? TotalReceivables() : 0}
          ></Typography16>
        </Stack>
      </Box>
    </>
  );
}
