import { Link } from "react-router-dom";
// import BusinessNetwork1 from "../../assets/BussinessNetwork1.svg";
import Table1 from "./Busi3Table";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  MenuItem,
  Typography,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../components/businessNetwok/typography/TypographyText";
import { useSelector } from "react-redux";
import React from "react";
import { Typography14 } from "../../components/typography/Typography";
import { enqueueSnackbar } from "notistack";
import ApiService from "../../api/api";
import { MenuProps } from "../../constant/generalMixing";

const Busi1 = () => {
  const searchType = [
    { value: "gst", label: "GST" },
    { value: "businessName", label: "Business Name" },
  ];
  const [totalCount, setTotalCount] = useState(0);
  const [businessId, setBusinessId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [UserSearchType, setUserSearchType] = useState("gst");
  const newToken = useSelector((state: any) => state.user.Token);
  const [userCount, setuserCount] = useState<any>({});
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("");

  // userlist for save data from all users
  const [UserList, setUserList] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const [verifyLoader, setVerifyLoader] = useState(false);
  const getUsersList = async () => {
    setVerifyLoader(true);
    let gstSearchValue;
    let businessNameSearchValue;

    // Condition for params empty or not
    if (UserSearchType === "gst") {
      businessNameSearchValue = "";
      gstSearchValue = searchValue;
    } else {
      gstSearchValue = "";
      businessNameSearchValue = searchValue;
    }

    let params = {
      page: page + 1,
      limit: rowsPerPage,
      status: userKycStatusValue,
      businessName: businessNameSearchValue,
      gst: gstSearchValue,
    };

    // User token check
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      `/userkyc/getBusinessNetwork`,
      params
    );

    if (responseData.status === 200) {
      let count: any = responseData.data.result;
      let data: any = responseData.data.result.data;
      let totalCount: any = responseData.data.result.total;

      const extractedBusinessIds = data.map(
        (item: any) => item.businessDetails.businessUser
      );

      setBusinessId(extractedBusinessIds);
      setTotalCount(totalCount);
      setuserCount(count);

      if (data) {
        if (data.length > 0) {
          setUserList(data);
        } else {
          setUserList([]);
        }
      } else {
        setUserList([]);
      }
      setVerifyLoader(false);
    } else {
      setVerifyLoader(false);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [UserSearchType, searchValue, userKycStatusValue]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
  };

  const handleClickInActiveStatus = async (value: any) => {
    let params = {
      businessId: value,
    };
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.put(
      `/userkyc/updateBusinessNetwork`,
      { status: "inActive" },
      params
    );
    if (responseData.status === 200) {
      enqueueSnackbar("Status Disabled Successfully", {
        variant: "error",
        style: {
          color: "white",
          // color: "rgba(54, 179, 126, 1)",
          backgroundColor: "#FF7276",
          marginRight: "100px",
          fontFamily: "Public Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
          maxWidth: "400px",
          margin: "50px 0 0 0",
        },
      });
      getUsersList();
    }
  };
  const handleClickActiveStatus = async (value: any) => {
    let params = {
      businessId: value,
    };
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.put(
      `/userkyc/updateBusinessNetwork`,
      { status: "active" },
      params
    );
    if (responseData.status === 200) {
      enqueueSnackbar("Status Enabled Successfully", {
        variant: "success",
        style: {
          color: " var(--success-darker, #0A5554)",
          // color: "rgba(54, 179, 126, 1)",
          backgroundColor: "var(--success-lighter, #D8FBDE)",
          marginRight: "100px",
          fontFamily: "Public Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "22px",
          maxWidth: "400px",
          margin: "50px 0 0 0",
        },
      });
      getUsersList();
    }
  };

  return (
    <>
      <Stack mx={4} sx={{ marginTop: { xs: "110px", md: "50px" } }}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"24px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          color={"#000000"}
        >
          Business Network
        </Typography>
        <Stack display={"flex"} direction={"row"} spacing={2}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Public Sans",
            }}
          >
            Dashboard
          </Typography>
          <Typography>.</Typography>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"14px"}
            fontWeight={400}
            color={"rgba(145, 158, 171, 1)"}
          >
            Business Network
          </Typography>
        </Stack>
        <Box
          display={"flex"}
          sx={{ justifyContent: { xs: "start", sm: "end" } }}
        >
          <Link to={"/dashboard/businesslist"}>
            <Button
              size="medium"
              sx={{
                color: "white",
                borderRadius: "8px",
                backgroundColor: "#303981",
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#303981",
                },
              }}
            >
              <AddIcon sx={{ mr: 1 }} fontSize="small" />
              <Typography14 color={"white"} text={"Add Business Network"} />
            </Button>
          </Link>
        </Box>
      </Stack>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={{
          backgroundColor: "#F7F9FB",
          paddingBottom: "40px",
        }}
      >
        <Box
          boxShadow="0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          mx={4}
          my={3}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Tabs
            value={userKycStatusValue}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="secondary"
          >
            <Tab
              value=""
              sx={{ textTransform: "none", px: 2 }}
              label={
                <Stack display={"flex"} direction={"row"} spacing={1.5}>
                  <Chips
                    count={userCount.total ? userCount.total : 0}
                    chipColor={"rgba(0, 184, 217, 0.16)"}
                    chipFontColor={"#006C9C"}
                  />
                  <TypographyText color={"black"} text={"All"} />
                </Stack>
              }
            />
            <Tab
              value="active"
              sx={{ textTransform: "none", px: 4 }}
              label={
                <Stack display={"flex"} direction={"row"} spacing={1.5}>
                  <Chips
                    count={
                      userCount.activeStatusCount
                        ? userCount.activeStatusCount
                        : 0
                    }
                    chipColor={"rgba(54, 179, 126, 0.16)"}
                    chipFontColor={"rgba(27, 128, 106, 1)"}
                  />
                  <TypographyText
                    color={"rgba(99, 115, 129, 1)"}
                    text={"Active"}
                  />
                </Stack>
              }
            />
            <Tab
              value="inActive"
              sx={{ textTransform: "none", px: 4 }}
              label={
                <Stack display={"flex"} direction={"row"} spacing={1.5}>
                  <Chips
                    count={
                      userCount.inactiveStatusCount
                        ? userCount.inactiveStatusCount
                        : 0
                    }
                    chipColor={"rgba(255, 171, 0, 0.16)"}
                    chipFontColor={"rgba(183, 110, 0, 1)"}
                  />
                  <TypographyText
                    color={"rgba(99, 115, 129, 1)"}
                    text={"Disabled"}
                  />
                </Stack>
              }
            />
          </Tabs>
          <Divider sx={{ color: "rgba(145, 158, 171, 0.24)" }} />
          <Stack
            sx={{ mx: 4, py: 2 }}
            display={"flex"}
            direction={"row"}
            spacing={1}
          >
            <FormControl sx={{ minWidth: "12vw" }}>
              <InputLabel
                sx={{
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  fontSize: "14px",
                  backgroundColor: "white",
                }}
              >
                Search Type
              </InputLabel>
              <Select
                value={UserSearchType ? UserSearchType : ""}
                onChange={(e) => setUserSearchType(e.target.value)}
                sx={{
                  border: "none",
                  borderRadius: "8px",
                }}
              >
                {searchType.map((name, index) => (
                  <MenuItem key={index} value={name.value}>
                    <Typography
                      fontFamily={"Public Sans"}
                      fontSize={"13px"}
                      fontWeight={400}
                    >
                      {name.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              onChange={(e) => setsearchValue(e.target.value)}
              placeholder="Search gst number, mobile number ..... "
              InputProps={{
                style: { borderRadius: "10px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box>
            {verifyLoader ? (
              <Box display={"flex"} justifyContent={"center"} py={15}>
                <CircularProgress
                  color="inherit"
                  sx={{ color: "grey" }}
                  size={30}
                />
              </Box>
            ) : (
              <Table1
                props={UserList}
                handleClickInActiveStatus={handleClickInActiveStatus}
                handleClickActiveStatus={handleClickActiveStatus}
              />
            )}
          </Box>
          <Box>
            <Divider />
            <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>{" "}
    </>
  );
};

export default Busi1;
