import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Box, Chip, Stack, Typography, Tooltip } from "@mui/material";
import NoRecordFound from "../../components/NoRecordFound";
import { margin, width } from "@mui/system";

export default function DenseTable(props: any) {
  const kycUserList: any = props ? props.props : [];

  const chipBackgroundColor = (value: any) => {
    if (value === "active") {
      return "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))";
    } else {
      return "var(--transparent-error-16, rgba(255, 86, 48, 0.16))";
    }
  };

  const chipTextColor = (value: any) => {
    if (value === "active") {
      return "var(--primary-dark, #007B55)";
    } else {
      return "var(--error-dark, #B71D18)";
    }
  };

  const [selectedItem, setSelectedItem] = useState<any>({});

  const handleStatusChange = (item: any) => {
    setSelectedItem(item);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell sx={{ borderBottom: "none", px: 3 }}>
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Business
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Contact
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Industry Type
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Products
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}>
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Status
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "none" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kycUserList.length > 0 ? (
            kycUserList.map((item: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 3,
                    }}
                  >
                    <Stack direction={"row"} display={"flex"} spacing={1}>
                      <Box>
                        <Stack>
                          <Typography
                            fontSize={13}
                            color={"#303891"}
                            fontFamily={"Public Sans"}
                            fontWeight={600}
                          >
                            {item.businessDetails &&
                            item.businessDetails.Legal_Name_of_Business !== ""
                              ? item.businessDetails.Legal_Name_of_Business
                              : "N/A"}
                          </Typography>
                          <Typography
                            fontSize={11}
                            color={"#637381"}
                            fontWeight={400}
                          >
                            {item.businessDetails && item.businessDetails.GSTIN_of_the_entity
                              ? item.businessDetails.GSTIN_of_the_entity
                              : "N/A"}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.userDetails && item.userDetails.business_mobile
                        ? item.userDetails.business_mobile
                        : "N/A"}
                    </Typography>
                    <Typography
                      fontSize={12}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.userDetails && item.userDetails.business_email
                        ? item.userDetails.business_email
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.industryDetails &&
                      item.industryDetails.industryName !== ""
                        ? item.industryDetails.industryName
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.productDetails && item.productDetails[0].productName !== ""
                        ? item.productDetails[0].productName
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Chip
                      label={item.status}
                      style={{
                        backgroundColor: chipBackgroundColor(item.status),
                        color: chipTextColor(item.status),
                        fontWeight: 700,
                        fontSize: 12,
                        lineHeight: "20px",
                        fontFamily: "Public Sans",
                      }}
                      sx={{
                        minHeight: "24px",
                        minWidth: "32px",
                        "& .MuiChip-label": {
                          fontWeight: 700,
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Tooltip
                      title={
                        item.status === "inActive" ? "Enable" : "Disable"
                      }
                      arrow
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {item.status === "inActive" ? (
                          <Typography
                            fontSize={14}
                            fontFamily={"Public Sans"}
                            sx={{ p: 1, background: "#e9e9e9", borderRadius: "10px" }}
                            fontWeight={600}
                            onClick={() =>
                              props.handleClickActiveStatus(
                                item.businessDetails?.businessUser
                              )
                            }
                          >
                            Enable
                          </Typography>
                        ) : (
                          <Typography
                            fontSize={14}
                            fontFamily={"Public Sans"}
                            sx={{ p: 1, background: "#e9e9e9", borderRadius: "10px" }}
                            fontWeight={600}
                            onClick={() =>
                              props.handleClickInActiveStatus(
                                item.businessDetails?.businessUser
                              )
                            }
                          >
                            Disable
                          </Typography>
                        )}
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
      <TableCell colSpan={6} sx={{ textAlign: 'center', padding: '20px' }}>
        <NoRecordFound
          text1={"Business"}
          text2={"business"}
          style={{
            display: 'flex',
            justifyContent: 'center', // Centers horizontally
            alignItems: 'center', // Centers vertically
            width: '100%', // Takes the full width of the container
            height: '100%', // Optional: Ensures full height to center vertically
            textAlign: 'center', // Ensures text is centered
            margin: 'auto', // Ensures the component is centered
          }}
        />
      </TableCell>
    </TableRow>
  )}
        </TableBody>
      </Table>
    </>
  );
}
