import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  Grid,
  Tooltip,
  Typography,
  Avatar,
  Rating,
} from "@mui/material";
import PaymentConfirmIcon from "../../../../../assets/icons8-complete.gif";
import { Icon } from "@iconify/react";

import { Typography16 } from "../../../../../components/typography/Typography";
import OrderStack from "../../../Reusable/OrderStack";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PaymentActionButton from "../../../Reusable/PaymentActionButton";
import { SuccessMsg } from "../../../../../components/Messages/SuccessMsg";
import SimpleLoader from "../../../../../components/Loader";
import { fontFamilys, fontSizes } from "../../../../../utils/FontSize";
import useCopyToClipboard from "../../../../../hooks/useCopyToClipboard";
import { removeSubstring } from "../../../../../utils/Validations";
import ApiService from "../../../../../api/api";
import PaymentConfirmDialog from "../../../Reusable/PaymentConfirmDialog";
import { ErrorMsg } from "../../../../../components/Messages/ErrorMsg";
import OrderDetails from "../../../Orders/OrderDetails";
import ReceivableTableList from "../../../CreatePaymentSeller/ReceivableTableList";
const GeneratePaymentRequestSeller = (props: any) => {
  const navigate = useNavigate();

  const Token = useSelector((state: any) => state.user.Token);
  const location = useLocation();
  const [open, setopen] = useState(false);
  const orderId = location.state
    ? location.state.orderDetails
    : props.orderDetails.order;
  const [orderDetails, setorderDetails] = useState<any>({});
  const [CancelPaymentDialog, setCancelPaymentDialog] = useState(false);

  const orderDetailss = async () => {
    let id = orderId ? orderId._id : "";
    setopen(true);

    // userToken check
    await ApiService.setToken(Token);
    const responseData: any = await ApiService.get(
      `/checkerRoutes/request/payment/created/${id}`
    );
    if (responseData.status === 200) {
      let data: any = responseData.data.data;
      setorderDetails(data);
      setopen(false);
    } else {
      setopen(false);
    }
  };
  useEffect(() => {
    orderDetailss();
  }, []);

  //  Redirect To Chat Page
  const RedirectToSellerChat = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };
  function formatString(inputString: string) {
    // Splitting the string based on underscore
    const splitString = inputString.split("_");
    // Capitalizing the first character of each word and joining them with space
    const result = splitString
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return result;
  }
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  const orderDetailsBox = (label: string, value: any) => {
    return (
      <Box>
        <Typography
          fontSize={fontSizes.xsm}
          fontFamily={fontFamilys.publicSans}
          color="#919EAB"
        >
          {label}
        </Typography>
        <Typography
          color={label === "Amount" ? "green" : "#212B36"}
          fontWeight={label === "Amount" ? 700 : 500}
          sx={{ maxWidth: "400px" }}
          fontSize={fontSizes.sm}
          fontFamily={fontFamilys.publicSans}
        >
          {value ? value : "N/A"}
        </Typography>
      </Box>
    );
  };
  // Dialog Open For Cancel Payment
  const openCanclePayment = () => {
    setCancelPaymentDialog(true);
  };
  // Dialog Close For Cancle Payment
  const closeCanclePayment = () => {
    setCancelPaymentDialog(false);
  };
  const CancelPaymentConfirmed = async () => {
    let body = {};
    if (Token !== "" && Token !== undefined) {
      await ApiService.setToken(Token);
      const responseData: any = await ApiService.patch(
        `/checkerRoutes/request/payment/cancel/${orderId._id}`,
        body
      );
      if (responseData.status === 200) {
        setCancelPaymentDialog(false);
        SuccessMsg("Payment order canceled successfully !");
        navigate("/dashboard/main");
      } else {
        ErrorMsg(`${responseData.response.data.error!}`);
      }
    }
  };
  return (
    <div>
      {" "}
      {open ? (
        <SimpleLoader props={open} />
      ) : (
        <>
          <Box pb={2}>
            {/*  step 1 */}
            <OrderStack
              props={orderDetails}
              OrderText={"Business Order"}
              titleIcon={PaymentConfirmIcon}
              titleIconColor={"#36B37E"}
              confirmationTitle={"Waiting for buyer to respond"}
              confirmationSubtitle={`You will be notified once ${
                orderDetails &&
                orderDetails.target &&
                orderDetails.target.Legal_Name_of_Business
              } complete the
              payment`}
            />

            {/* step2 */}
            <Box
              justifyContent={"center"}
              component={"div"}
              sx={{
                backgroundColor: "#F7F9FB",
              }}
            >
              <Box
                boxShadow={0.5}
                mx={4}
                sx={{
                  borderRadius: "16px",
                  backgroundColor: "white",
                  border: "1px solid #EEF2FA",
                  boxShadow:
                    "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                }}
              >
                {/*  Payment Order Details & Payment Seller Details */}
                <OrderDetails props={orderDetails} />
                <Box px={5} mt={1.5}>
                  <Typography
                    fontFamily={fontFamilys.publicSans}
                    fontWeight={700}
                  >
                    {orderDetails &&
                      orderDetails.target &&
                      orderDetails.target.targetType}{" "}
                    Details
                  </Typography>
                </Box>
                <Box
                  px={8}
                  mx={5}
                  // my={1}
                  pt={1}
                  sx={{
                    backgroundColor: " var(--grey-100, #F9FAFB)",
                    border: "1px solid var(--grey-300, #DFE3E8)",
                    borderRadius: "7px",
                    maxHeight: "160px",
                  }}
                >
                  <Box maxWidth={"400px"} mt={1.2}>
                    <Typography16
                      fontWeight={700}
                      // color="#303981"
                      color="#212B36"
                      text={
                        orderDetails.target
                          ? orderDetails.target.Legal_Name_of_Business
                          : "N/A"
                      }
                    ></Typography16>
                  </Box>
                  <Box sx={{ px: 2, py: 2 }}>
                    <Grid
                      container
                      sx={
                        {
                          // my: 2,
                        }
                      }
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid
                        style={{
                          padding: "8px",
                        }}
                        sx={{ my: 1 }}
                        xs={2}
                        sm={3}
                        md={3}
                      >
                        <Stack direction={"row"} spacing={1}>
                          <Avatar
                            src={
                              orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.aadharPhotoLink
                            }
                          />
                          {orderDetailsBox(
                            " Authorised Business Representative",
                            orderDetails && orderDetails.target
                              ? orderDetails.target.nameInAadhaar
                              : "N/A"
                          )}
                        </Stack>

                        <Box mt={2}>
                          <Typography
                            component="legend"
                            fontSize={"11px"}
                            color={"#919EAB"}
                          >
                            Rating
                          </Typography>
                          <Rating
                            name="simple-controlled"
                            value={4}
                            size="small"
                          />
                        </Box>
                      </Grid>
                      <Grid
                        style={{
                          padding: "8px",
                        }}
                        sx={{ my: 1 }}
                        xs={2}
                        sm={3}
                        md={3}
                      >
                        <Box>
                          <Stack direction={"row"}>
                            {orderDetailsBox(
                              "Contact",
                              orderDetails && orderDetails.target
                                ? orderDetails.target.business_mobile
                                : "N/A"
                            )}
                            <Box pt={1.5}>
                              <Tooltip title="Copy" placement="top">
                                <IconButton
                                  onClick={() =>
                                    onCopy(
                                      orderDetails &&
                                        orderDetails.target &&
                                        orderDetails.target.business_mobile
                                    )
                                  }
                                >
                                  <Icon icon="eva:copy-fill" width={20} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Stack>
                        </Box>

                        <Box mt={1}>
                          <Typography
                            component="legend"
                            fontSize={"11px"}
                            color={"#919EAB"}
                          >
                            Since
                          </Typography>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            lineHeight={"22px"}
                            fontWeight={600}
                          >
                            2023
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        style={{
                          padding: "8px",
                        }}
                        sx={{ my: 1.2 }}
                        xs={2}
                        sm={3}
                        md={3}
                      >
                        <Tooltip
                          title={
                            orderDetails &&
                            orderDetails.target &&
                            orderDetails.target.Place_of_Business
                          }
                        >
                          {orderDetailsBox(
                            "Address",
                            orderDetails &&
                              orderDetails.target &&
                              removeSubstring(
                                orderDetails.target.Place_of_Business
                              )
                          )}
                        </Tooltip>
                        <Box mt={2}>
                          <Typography
                            component="legend"
                            fontSize={"12px"}
                            color={"#919EAB"}
                          >
                            Orders Full-filed
                          </Typography>
                          <Stack spacing={1} display={"flex"} direction={"row"}>
                            <Typography
                              fontFamily={"Public Sans"}
                              fontSize={"14px"}
                              lineHeight={"22px"}
                              fontWeight={600}
                            >
                              {orderDetails &&
                                orderDetails.target &&
                                orderDetails.target.ordersFullfilled}{" "}
                              /{" "}
                              {orderDetails &&
                                orderDetails.target &&
                                orderDetails.target.totalOrders}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                      <Grid
                        style={{
                          padding: "8px",
                        }}
                        sx={{ my: 1.2 }}
                        xs={2}
                        sm={3}
                        md={3}
                      >
                        <Stack direction={"row"}>
                          {orderDetailsBox(
                            "GST",
                            orderDetails && orderDetails.target
                              ? orderDetails.target.GSTIN_of_the_entity
                              : "N/A"
                          )}
                          <Box pt={1.5}>
                            <Tooltip title="Copy" placement="top">
                              <IconButton
                                onClick={() =>
                                  onCopy(
                                    orderDetails &&
                                      orderDetails.target &&
                                      orderDetails.target.GSTIN_of_the_entity
                                  )
                                }
                              >
                                <Icon icon="eva:copy-fill" width={20} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>

                        <Box mt={2}>
                          <Stack
                            textAlign={"start"}
                            // spacing={5}
                            sx={
                              {
                                // px: 3,
                                // py: 1.5,
                              }
                            }
                          >
                            <Box>
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                                // mt={-2}
                              >
                                Connections
                              </Typography>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={"14px"}
                                lineHeight={"22px"}
                                fontWeight={600}
                              >
                                {(orderDetails &&
                                  orderDetails.target &&
                                  orderDetails.target.connections) ||
                                  0}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                      {/*  */}
                    </Grid>
                  </Box>
                </Box>

                <ReceivableTableList props={orderDetails} />
                {/* Blocked or Used Bank Balance AND Receivables Balance */}
                <Box>
                  <PaymentActionButton
                    // RedirectToSellerChat={RedirectToSellerChat}
                    ButtonTextOne={"Chat with Buyer"}
                    Button2TrueFalse={true}
                    Button1TrueFalse={true}
                    ButtonColor1={"#303981"}
                    ButtonTextTwo={"Cancel Request"}
                    ButtonColor2={"#FF5630"}
                    openAcceptPayment={openCanclePayment}
                  />
                  <PaymentConfirmDialog
                    OpenDailog={CancelPaymentDialog}
                    ClosePaymentAcceptDialog={closeCanclePayment}
                    ApprovedPaymentConfigure={CancelPaymentConfirmed}
                    TitleText={"Payment request Confirmation"}
                    ConfirmationText={
                      "Are you sure you want to proceed with this request cancellation ?"
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default GeneratePaymentRequestSeller;
