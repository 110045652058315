import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";
import R_Wallet from "../../../../../assets/ReceivableWallet.svg";
import { useNavigate } from "react-router-dom";
import ModalDialog from "../../../../../components/Dialog/ModelDialog";

import {
  numberFormat,
  numberToEnglish,
} from "../../../../../utils/Validations";

const Ap_Receivable = (props: any) => {
  const [apKnowMoreOpen, setapKnowMoreOpen] = useState(false);
  const modelTitle = "Model Title";
  const modelSubtitle =
    "This refers to the payments you are scheduled to receive in the coming future.";

  const navigate = useNavigate();
  return (
    <Grid item xs={6}>
      <Stack sx={{ p: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          AssuredPay Receivables
          <img src={R_Wallet} height={20} width={30} alt="" />
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "9px",
            }}
          >
            My future receivables, payments & pre-claim.
          </Typography>
          <InfoIcon
            sx={{ color: "#FFF", height: 25, width: 25 }}
            fontSize="small"
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          />
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Public Sans",
              color: "#FFF",
              fontSize: "10px",
              textDecoration: "underline",
              fontWeight: 600,
            }}
            onClick={() => setapKnowMoreOpen(!apKnowMoreOpen)}
          >
            Know More
          </Typography> */}
          <ModalDialog
            open={apKnowMoreOpen}
            setapKnowMoreOpen={setapKnowMoreOpen}
            modelTitle={"AssuredPay Receivables"}
            modelSubtitle={
              "This refers to the payments you are scheduled to receive in the coming future. "
            }
            BgColor={"#36A979"}
            TextColor={"#FFFFFF"}
          />
        </Stack>
      </Stack>
      <Box px={2} height={60}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
          }}
        >
          {numberFormat(props?.Av_Receivable?.receivables?.totalReceivable)}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Public Sans",
            color: "#FFF",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "12px",
          }}
        >
          {props?.Av_Receivable?.receivables?.totalReceivable > 0
            ? numberToEnglish(
                parseInt(props?.Av_Receivable?.receivables?.totalReceivable)
              ).toLocaleUpperCase()
            : "YOU DON'T HAVE ANY ASSURED RECEIVABLE"}
        </Typography>
      </Box>
      <Box my={2} mx={2}>
        <LoadingButton
          size="small"
          disabled={props?.Av_Receivable?.receivables?.totalReceivable <= 0}
          onClick={() => navigate("/dashboard/availablerecievables")}
          sx={{ px: 2 }}
          style={{
            textTransform: "none",
            color:
              props?.Av_Receivable?.receivables?.totalReceivable <= 0
                ? "#A0A0A0" // Soft grey for text
                : "#FFFFFF",
            backgroundColor:
              props?.Av_Receivable?.receivables?.totalReceivable <= 0
                ? "linear-gradient(90deg, #F5F5F5 0%, #D6D6D6 100%)" // Soft gradient
                : "#36A979",
            borderRadius: "8px",
            fontSize: "11px",
            border:
              props?.Av_Receivable?.receivables?.totalReceivable <= 0
                ? "1px solid #D0D0D0" // Lighter border for disabled
                : "1px solid #fff",
            fontWeight: "700",
          }}
        >
          View Receivables
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default Ap_Receivable;
