import RemainingIcon from "../../assets/ic_info.svg";
import { experimentalStyled as styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Stack,
  Container,
  Typography,
  Button,
  TextField,
  Paper,
  Avatar,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import ProgressBarCircular from "../../components/ProgressBarCircular";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import { characterUpperCase } from "../../utils/Validations";
import ApiService from "../../api/api";
import { useSelector } from "react-redux";
import { changeTextAdharProfile } from "../../utils/AadharProfileTextArrange";

const AadharVerify = () => {
  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.user.Token);
  const [UserAadharNumber, setUserAadharNumber] = useState("");
  const [OtpbtnDisabled, setOtpBtnDisabled] = useState(true);
  const [VerifyBtnDisabled, setVerifyBtnDisabled] = useState(true);
  const [showVerifyOtpOption, setshowVerifyOtpOption] = useState(false);
  const [UserAadharNumberDisabled, setUserAadharNumberDisabled] =
    useState(false);
  const [showAadharVerifyMsg, setshowAadharVerifyMsg] = useState(false);
  const [verifiedUserAdharData, setverifiedUserAdharData] = useState<any>([]);
  const [uploadAdharbtnDisabled, setuploadAdharbtnDisabled] = useState(true);
  const [AdharOTPErrMsg, setAdharOTPErrMsg] = useState(false);
  const [verifyAdharPhoto, setverifyAdharPhoto] = useState("");
  const [VerifyLoading, setVerifyLoading] = useState(false);
  const [verifyAadharLoading, setverifyAadharLoading] = useState(false);
  const [RemainingCount, setRemainingCount] = useState(false);
  const [EmptyAadharAttempt, setEmptyAadharAttempt] = useState("");
  const [remainingTime, setRemainingTime] = useState(60);
  const [isResendOtpEnabled, setIsResendOtpEnabled] = useState(false);
  const [fetchAdharDetails, setfetchAdharDetails] = useState(false);
  const userRole = localStorage.getItem("userRole");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
  const handleAdharGetOtp = async (e: any) => {
    e.preventDefault();
    setVerifyLoading(true);
    setOtpBtnDisabled(true);

    if (UserAadharNumber !== "") {
      const data = new FormData(e.currentTarget);
      let body = { AadharNumber: data.get("UserAadharNumber") };
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.post(
        "/userkyc/verifyadhar",
        body
      );
      if (responseData.status === 200) {
        setRemainingCount(responseData.data.leftAttempt);
        setVerifyLoading(false);
        setUserAadharNumberDisabled(true);
        setOtpBtnDisabled(true);
        setshowVerifyOtpOption(true);
        SuccessMsg("OTP send to your Aadhar registered mobile number");
      } else {
        setEmptyAadharAttempt(
          "Your Aadhar verification limit is exceed now, Please contact to AssuredPay Support Team!"
        );
        setAdharOTPErrMsg(true);
        setVerifyLoading(false);
        ErrorMsg(`${"Invalid Aadhar Number, Please try again"}`);
      }
    }
  };
  const handleAdharVerify = async (e: any) => {
    e.preventDefault();
    setverifyAadharLoading(true);
    setVerifyBtnDisabled(true);
    if (UserAadharNumber !== "") {
      const data = new FormData(e.currentTarget);
      let body = {
        otp: data.get("userGstNumber"),
        aadharNum: UserAadharNumber,
      };
      await ApiService.setToken(newToken);
      const responseData: any = await ApiService.post(
        "/userkyc/verifyadharotp",
        body
      );
      if (responseData.status === 200) {
        const aadhaarData = responseData.data.results.resultSend;
        const aadhaarPhotoLink =
          responseData.data.results.resultSendImage.aadharPhotoLink;

        setverifiedUserAdharData(aadhaarData);
        setverifyAdharPhoto(aadhaarPhotoLink);
        setfetchAdharDetails(true);
        setverifyAadharLoading(false);
        setshowVerifyOtpOption(false);
        setshowAadharVerifyMsg(true);
        setuploadAdharbtnDisabled(false);
      } else {
        setverifyAadharLoading(false);
      }
      setVerifyBtnDisabled(false);
    }
  };

  const handleOnClickfileUploadSuccess = () => {
    navigate("/auth/verifypan");
  };
  const handleGetOtpBtn = (e: any) => {
    setAdharOTPErrMsg(false);
    const TrimGst = e.target.value ? e.target.value.trim() : "";
    setUserAadharNumber(TrimGst);
    if (e.target.value && TrimGst.length === 12) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
  };

  const handleOnChangeVerifyUserAadharOTP = (e: any) => {
    if (e.target.value !== "") {
      setVerifyBtnDisabled(false);
    } else {
      setVerifyBtnDisabled(true);
    }
  };
  const handleResendOtp = async () => {
    setIsResendOtpEnabled(false); // Disable the button while processing
    setVerifyLoading(true);

    // Call your API to resend OTP here
    const body = { AadharNumber: UserAadharNumber }; // Use the same Aadhar number
    await ApiService.setToken(newToken);
    const responseData: any = await ApiService.post(
      "/userkyc/verifyadhar", // Adjust this if your API endpoint is different
      body
    );

    if (responseData.status === 200) {
      setRemainingCount(responseData.data.leftAttempt);
      SuccessMsg("OTP sent to your Aadhar registered mobile number");
    } else {
      ErrorMsg("Failed to send OTP. Please try again.");
    }

    // Reset the timer
    setRemainingTime(60);
    setVerifyLoading(false);
  };

  useEffect(() => {
    let timer: any;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setOtpBtnDisabled(false); // Enable button after 1 minute
      setIsResendOtpEnabled(true); // Enable resend OTP button
    }
    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [remainingTime]);

  return (
    <Box margin={2} sx={{ flexGrow: 1 }}>
      <Box textAlign={"center"}>
        <Stack sx={{ px: "100px" }}>
          <CustomizedSteppers activeStep={2} />
        </Stack>
      </Box>

      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          boxShadow={"1"}
          justifyContent={"center"}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            py: 3,
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 } }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={21}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Enter Aadhar Details for Director / Partner / Promoter /
                Proprietor only.
              </Typography>
            </Grid>
            {/* <Grid container columnSpacing={1} sx={{ order: { xs: 12, sm: 2 } }}>
              <Grid>
                <ProgressBarCircular percentage={30} />
              </Grid>
            </Grid> */}
          </Grid>
          <Box>
            <>
              <Box sx={{ my: 3 }} component="form" onSubmit={handleAdharGetOtp}>
                {!fetchAdharDetails}
                <Stack direction={"row"}>
                  <TextField
                    disabled={UserAadharNumberDisabled}
                    label="Enter Your Aadhar Number"
                    size="small"
                    type="text"
                    error={AdharOTPErrMsg}
                    name="UserAadharNumber"
                    value={characterUpperCase(UserAadharNumber)}
                    onChange={handleGetOtpBtn}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        borderColor: "#919EAB",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 40,
                        minWidth: "23vw",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        Height: "20px",
                      },
                    }}
                  />{" "}
                  {showAadharVerifyMsg ? (
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ ml: "20px", mt: 1 }}
                    >
                      <>
                        <DoneIcon
                          style={{ fontSize: "15px", color: "#36B37E" }}
                        />{" "}
                        <Typography
                          fontWeight={600}
                          fontSize={11}
                          fontFamily={"Public Sans"}
                          color={"#36B37E"}
                        >
                          {" "}
                          Aadhar Details Fetched SuccessFully
                        </Typography>
                      </>
                    </Stack>
                  ) : (
                    <LoadingButton
                      size="small"
                      disabled={OtpbtnDisabled}
                      sx={{ px: 1.5 }}
                      style={{
                        marginLeft: 12,
                        textTransform: "none",
                        backgroundColor: OtpbtnDisabled
                          ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                          : "#00AB55",
                        color: OtpbtnDisabled
                          ? "rgba(145, 158, 171, 0.80)"
                          : "white",
                        borderRadius: "8px",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                      loading={VerifyLoading}
                      type="submit"
                    >
                      Get OTP
                    </LoadingButton>
                  )}
                </Stack>
              </Box>
              {showVerifyOtpOption ? (
                <Box
                  sx={{ my: 2 }}
                  component="form"
                  onSubmit={handleAdharVerify}
                >
                  <Stack direction={"row"}>
                    <TextField
                      label="Enter Your Aadhar OTP"
                      size="small"
                      type="text"
                      name="userGstNumber"
                      onChange={handleOnChangeVerifyUserAadharOTP}
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          borderColor: "#919EAB",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                          minWidth: "23vw",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontFamily: "Public Sans",
                          fontWeight: "500",
                          fontSize: "14px",
                          Height: "20px",
                        },
                      }}
                    />{" "}
                    <>
                      <LoadingButton
                        size="small"
                        sx={{ py: 0.1, px: 3, mt: 0.2 }}
                        style={{
                          marginLeft: 12,
                          textTransform: "none",
                          backgroundColor: VerifyBtnDisabled
                            ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                            : "#00AB55",
                          color: VerifyBtnDisabled
                            ? "rgba(145, 158, 171, 0.80)"
                            : "white",
                          borderRadius: "8px",
                          fontSize: "13px",
                          fontWeight: "700",
                        }}
                        loading={verifyAadharLoading}
                        type="submit"
                      >
                        Verify
                      </LoadingButton>
                    </>
                    <> </>
                  </Stack>
                </Box>
              ) : (
                <></>
              )}
            </>
          </Box>

          <Box>
            {showVerifyOtpOption ? (
              <>
                <Stack display={"flex"} direction={"column"} spacing={0.5}>
                  <Typography
                    fontSize={12}
                    color={"var(--text-light-secondary, #637381)"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    fontWeight={400}
                  >
                    {" "}
                    Remaining attempt : {RemainingCount ? RemainingCount : 0}
                  </Typography>
                  <Typography
                    fontSize={12}
                    color={"var(--text-light-secondary, #637381)"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    fontWeight={400}
                  >
                    Remaining time: {remainingTime}s
                  </Typography>
                  <LoadingButton
                    size="small"
                    disabled={!isResendOtpEnabled}
                    style={{
                      textTransform: "none",
                      backgroundColor: !isResendOtpEnabled
                        ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                        : "#00AB55",
                      color: !isResendOtpEnabled
                        ? "rgba(145, 158, 171, 0.80)"
                        : "white",
                      borderRadius: "8px",
                      fontSize: "12px",
                      fontWeight: "700",
                      width: "20%",
                    }}
                    loading={VerifyLoading}
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </LoadingButton>
                </Stack>
              </>
            ) : (
              ""
            )}
            {fetchAdharDetails ? (
              <Box sx={{ display: "flex", flexShrink: 0 }}>
                <Grid
                  container
                  flexDirection={{ xs: "column", sm: "row" }}
                  sx={{ fontSize: "12px" }}
                >
                  <Grid xs={10} alignItems={"center"}>
                    <Typography
                      component="div"
                      fontFamily={"Public Sans"}
                      fontSize={19}
                      fontWeight={700}
                      lineHeight={"30px"}
                      color={"#212B36"}
                    >
                      <Grid
                        container
                        sx={{
                          m: 0,
                          mt: 0.5,
                          ml: -0.5,
                          p: 0,
                          "& .MuiPaper-root": {
                            padding: "0px",
                          },
                        }}
                        spacing={{ xs: 3, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {/* {verifiedUserAdharData.map((name, index) => ( */}
                        {Object.keys(verifiedUserAdharData).map(
                          (name: any, index: any) => (
                            <Grid
                              xs={2}
                              sm={4}
                              md={4}
                              key={`${index}-${name}`} // Ensure unique key
                              style={{ padding: "8px" }}
                              sx={{ my: 0, p: 0 }}
                            >
                              <Item>
                                <Typography
                                  sx={{ m: 0, p: 0 }}
                                  fontFamily={"Public Sans"}
                                  fontSize={11}
                                  color={"#919EAB"}
                                >
                                  {changeTextAdharProfile(name) || "N/A"}
                                </Typography>
                                <Typography
                                  fontSize={"14px"}
                                  fontStyle={"normal"}
                                  fontFamily={"Public Sans"}
                                  color={"#212B36"}
                                  fontWeight={600}
                                  sx={{ m: 0, p: 0 }}
                                >
                                  {verifiedUserAdharData[name] || "N/A"}
                                </Typography>
                              </Item>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"center"}
                    // alignItems={"center"}
                    columnSpacing={0}
                    sx={{ order: { xs: 2, sm: 2 }, mt: 1 }}
                  >
                    {/* <ProgressBarCircular /> */}
                    <Avatar
                      alt=""
                      src={verifyAdharPhoto || "/static/images/avatar/1.jpg"}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <div style={{ minHeight: "30vh" }}>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={"10px"}
                  color={"red"}
                >
                  {EmptyAadharAttempt}
                </Typography>
              </div>
            )}
          </Box>
          <Box>
            <Stack display={"flex"} direction={"row"} sx={{ mt: 2 }}>
              <Button
                size="small"
                disabled={uploadAdharbtnDisabled}
                onClick={handleOnClickfileUploadSuccess}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  borderRadius: "8px",
                  px: 2,
                  background: uploadAdharbtnDisabled
                    ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                    : "#303981",
                  color: uploadAdharbtnDisabled ? "black" : "white",
                  "&:hover": {
                    background: uploadAdharbtnDisabled
                      ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                      : "#303981",
                  },
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AadharVerify;
