import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
// form
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { RHFTextField } from "../../../../components/hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  daysConversionFromDate,
  getPaymentDays,
  numberFormat,
  numberToEnglish,
} from "../../../../utils/Validations";
import { LoadingButton } from "@mui/lab";
import { SuccessMsg } from "../../../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import { Typography16 } from "../../../../components/typography/Typography";
import ApiService from "../../../../api/api";

type FormValuesProps = {
  title: string;
  paymentType: string;
  PO: string;
  paymentMethod: string;
  desc: string;
  amount: string;
  date: string;
  days: string;
  balanceUsed: number;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#303981",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ConfigurePayment({
  handleStepper,
  paymentSummaryData,
  confirmDetailData,
}: any) {
  const location = useLocation();
  const BusinessDetailsForPayment = location.state
    ? location.state.businessNetwork
    : {};
  const newToken = useSelector((state: any) => state.user.Token);
  // const REACT_APP_BASE_URL =
  // process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL;
  const REACT_APP_BASE_URL = "https://dev.api.assuredpay.in";

  const [receivableAmount, setReceivableAmount] = React.useState(0);
  const [availableBalance, setAvailableBalance] = React.useState(0);
  const [usedReceivables, setUsedReceivables] = React.useState<any>([]);
  const [allReceiablesData, setAllReceiablesData] = React.useState([]);

  //loading state for fetch receivables to make payment
  const [isFetchReceivableLoading, setIsFetchReceivableLoading] =
    React.useState(false);

  //state for saving draft
  const [isDraftLoading, setIsDraftLoading] = React.useState(false);
  const [isPaymentLock, setIsPaymentLock] = React.useState(false);

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // interest Table
  const [interestTable, setInterestTable] = React.useState([]);

  // Form Controller
  const FilterSchema = Yup.object().shape({
    balanceUsed: Yup.number()
      .typeError("Please Enter Valid Amount")
      .min(0, "Please Enter Amount")
      .max(
        +paymentSummaryData.amount,
        "You cannot enter more then Rs. " + paymentSummaryData.amount
      )
      .required("Please Enter valid Amount"),
  });
  // .typeError("Please Enter a Valid Amount")
  // .min(0, "Please Enter Amount")
  // .max(
  //   +paymentSummaryData.amount && receivableAmount,
  //   `You cannot enter more than Rs. ${
  //     paymentSummaryData.amount && receivableAmount
  //   }`
  // )
  // .required("Please Enter a Valid Amount"),
  // });
  const defaultValues = {
    balanceUsed: 0,
  };
  const methods = useForm<FormValuesProps | any>({
    resolver: yupResolver(FilterSchema),
    defaultValues,
    mode: "onChange",
  });
  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  React.useEffect(() => {
    fetchAmount();
  }, []);

  React.useEffect(() => {
    setUsedReceivables([]);
  }, [getValues("balanceUsed")]);

  //FETCH BANK BALANCE & TOTAL RECEIVEABLE
  const fetchAmount = async () => {
    const token = await ApiService.setToken(newToken);
    const dashboardData: any = await ApiService.get(
      `/checkerRoutes/getrecievablesdashboard`
    );
    if (dashboardData.status === 200) {
      let orderData = dashboardData.data.result;
      setReceivableAmount(orderData.totalReceivable);
    }

    if (dashboardData.status === 200) {
      const token = await ApiService.setToken(newToken);
      const dashboardData1: any = await ApiService.get(
        `/checkerRoutes/getwalletbalance`
      );
      if (dashboardData1.status === 200) {
        let orderData = dashboardData1.data.result;
        setAvailableBalance(orderData.availableBalance);
      }
    }
  };

  //FETCH ALL RECEIVALBLE FOR MODAL
  const viewAllReceivables = async () => {
    const token = await ApiService.setToken(newToken);
    const dashboardData1: any = await ApiService.get(
      `/checkerRoutes/getrecievables`
    );
    if (dashboardData1.status === 200) {
      setAllReceiablesData(dashboardData1.data.result);
      handleOpen();
    }
  };

  //FETCH eligible RECEIVALBLE to make payment
  const onSubmit = async (data: FormValuesProps) => {
    setIsFetchReceivableLoading(true);
    let body = {
      orderAmount: +paymentSummaryData.amount,
      milestoneAmount: +paymentSummaryData.amount,
      dueDate: paymentSummaryData.date,
      days: daysConversionFromDate(paymentSummaryData.date),
      bankBalance: data.balanceUsed,
    };

    const token = await ApiService.setToken(newToken);
    const dashboardData1: any = await ApiService.post(
      `/checkerRoutes/createOrder/getRecievables`,
      body
    );
    if (dashboardData1.status === 200) {
      if (dashboardData1.data.lowBankBalance) {
        ErrorMsg(
          `Please add Rs.${parseInt(
            dashboardData1.data.requiredAmount
          )} more in your wallet. your upcoming receivable Amount in less then total amount`
        );
        setUsedReceivables(dashboardData1.data.result);
      } else {
        setUsedReceivables(dashboardData1.data.result);
      }
      setIsFetchReceivableLoading(false);
    } else {
      setIsFetchReceivableLoading(false);
    }
  };

  //save Amount for draft and lock the UI and get Interest table
  const saveDraft = async () => {
    setIsDraftLoading(true);
    let usedReceivable =
      paymentSummaryData.amount !== +getValues("balanceUsed");
    let draftBodyArray: any = [];
    let i = 0;
    do {
      draftBodyArray.push({
        milestoneId: usedReceivable ? usedReceivables[i]?.id : "",
        orderAmount: +paymentSummaryData.amount,
        days: daysConversionFromDate(paymentSummaryData.date),
        bankBalanceUsed: +getValues("balanceUsed"),
        index: 0,
        milestoneAmount: +paymentSummaryData.amount,
        dueDate: usedReceivable
          ? usedReceivables[i]?.dueDate
          : paymentSummaryData.date,
        assuredRecievables: usedReceivable
          ? usedReceivables[i]?.assuredRecievables
          : 0,
        usableRecievables: usedReceivable
          ? usedReceivables[i]?.usableRecievables
          : 0,
        receivablesToBeUsed: usedReceivable
          ? usedReceivables[i]?.receivablesToBeUsed
          : 0,
        convenienceFee: usedReceivable ? usedReceivables[i]?.convenienceFee : 0,
        isFDAllowed: usedReceivable ? usedReceivables[i]?.isFDAllowed : false,
        fdDays: usedReceivable ? usedReceivables[i]?.fdDays : 0,
        approxInterest: usedReceivable ? usedReceivables[i]?.approxInterest : 0,
      });
      i++;
    } while (i < usedReceivables.length);

    await ApiService.setToken(newToken);
    const dashboardData1: any = await ApiService.post(
      `/checkerRoutes/selectRecievable`,
      draftBodyArray
    );
    if (dashboardData1.status === 200) {
      setInterestTable(dashboardData1.data.data);
      setIsPaymentLock(true);
      SuccessMsg(dashboardData1.data.message);
      setIsDraftLoading(false);
    } else {
      setIsDraftLoading(false);
    }
  };

  const resetDraft = async () => {
    await ApiService.setToken(newToken);
    const dashboardData1: any = await ApiService.delete(
      `/checkerRoutes/draft/milestone` + 0
    );
    if (dashboardData1.status === 200) {
      setInterestTable([]);
      setIsPaymentLock(false);
      setValue("balanceUsed", 0);
      SuccessMsg(dashboardData1.data.message);
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
  };

  const handleCopyClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      SuccessMsg("Copied!");
    } catch (err) {
      ErrorMsg("Unable to copy to clipboard.");
    }
  };

  return (
    <>
      {/* Summary data */}
      <Grid
        m={4}
        rowGap={1}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        <Stack>
          <Typography sx={{ color: "#b0bac4" }}>Order Title</Typography>
          <Typography variant="h6">{paymentSummaryData.title}</Typography>
        </Stack>
        <Stack>
          <Typography sx={{ color: "#b0bac4" }}>Payment Type</Typography>
          <Typography variant="h6">
            {paymentSummaryData.paymentType == "payment_against_invoice"
              ? "Payment Against Invoice"
              : paymentSummaryData.paymentType == "advanced_payment"
              ? "Advance Payment"
              : "Payment on Account"}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ color: "#b0bac4" }}>Order Type</Typography>
          <Typography variant="h6">
            {paymentSummaryData.paymentMethod}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ color: "#b0bac4" }}>PO/PI (Mandatory)</Typography>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
            <Typography variant="h6">{paymentSummaryData.PO}</Typography>
            <Icon
              icon="mingcute:copy-line"
              style={{ color: "#707070", fontSize: 20, cursor: "pointer" }}
              onClick={() => handleCopyClick(paymentSummaryData.PO)}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ color: "#b0bac4" }}>Invoice Amount</Typography>
          <Typography variant="h6">
            {numberFormat(paymentSummaryData.amount)}
          </Typography>
        </Stack>

        <Stack>
          <Typography sx={{ color: "#b0bac4" }}>Payment Days</Typography>
          <Typography variant="h6">
            {daysConversionFromDate(paymentSummaryData.date)} days
          </Typography>
        </Stack>
      </Grid>

      <Stack
        mx={4}
        mb={2}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid
          rowGap={2}
          columnGap={4}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(3, 1fr)",
          }}
        >
          <Stack>
            <Typography variant="body2" sx={{ color: "#b0bac4" }}>
              Available Balance
            </Typography>
            <Typography variant="h5" color={"#303981"}>
              {numberFormat(availableBalance)}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body2" sx={{ color: "#b0bac4" }}>
              Total Receivable
            </Typography>
            <Typography variant="h5" color={"#303981"}>
              {numberFormat(receivableAmount)}
            </Typography>
          </Stack>
        </Grid>

        <Stack flexDirection={"row"} alignItems={"center"}>
          <Button
            variant="contained"
            sx={{
              background: "#303981",
              borderRadius: 2,
              height: "fit-content",
              "&:hover": {
                backgroundColor: "#343d84db",
              },
            }}
            onClick={viewAllReceivables}
            disabled={isPaymentLock}
          >
            View all Receivables
          </Button>
        </Stack>
      </Stack>

      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(
          paymentSummaryData.amount == getValues("balanceUsed")
            ? saveDraft
            : onSubmit
        )}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={{ xs: "100%", sm: "50%" }}
          gap={3}
          ml={4}
        >
          <Stack>
            <RHFTextField
              name="balanceUsed"
              type="number"
              defaultValue={0}
              fullWidth
              label="Bank Balance"
              // sx={{ maxWidth: "50%" }}
              disabled={isPaymentLock}
              error={!!errors.balanceUsed}
            />
            <Typography variant="caption" sx={{ color: "#303981", ml: 1 }}>
              {numberToEnglish(parseInt(watch("balanceUsed")))}
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} alignItems={"center"}>
            {paymentSummaryData.amount == getValues("balanceUsed") ? (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isDraftLoading}
                disabled={isPaymentLock}
                sx={{
                  background: "#303981",
                  borderRadius: 2,
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: "#343d84db",
                  },
                }}
              >
                Continue
              </LoadingButton>
            ) : (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isFetchReceivableLoading}
                sx={{
                  background: "#303981",
                  borderRadius: 2,
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: "#343d84db",
                  },
                }}
                disabled={
                  !!(
                    +watch("balanceUsed") >= +availableBalance ||
                    +watch("balanceUsed") >= paymentSummaryData.amount ||
                    isPaymentLock
                  )
                }
              >
                Fetch Receivalbe
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </FormProvider>

      {usedReceivables.length > 0 && (
        <Card sx={{ m: 2 }}>
          <TableContainer>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Assured Receivables</StyledTableCell>
                  <StyledTableCell>Usable Receivables</StyledTableCell>
                  <StyledTableCell>Receivable to be used</StyledTableCell>
                  <StyledTableCell>AP Receivables Fees</StyledTableCell>
                  <StyledTableCell>Is FD Allowed</StyledTableCell>
                  <StyledTableCell>FD Days</StyledTableCell>
                  <StyledTableCell>Approx Interests</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usedReceivables.map((row: any) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {new Date(row.dueDate).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {row.assuredRecievables}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {row.usableRecievables}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {row.receivablesToBeUsed}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center", color: "red" }}>
                      {row.convenienceFee}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: "center",
                        color: row.isFDAllowed ? "green" : "red",
                      }}
                    >
                      {row.isFDAllowed ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {row.fdDays}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ textAlign: "center", color: "green" }}
                    >
                      {row.approxInterest}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"end"}
            gap={5}
            mx={4}
            my={2}
          >
            {usedReceivables.length > 0 && (
              <Typography variant="subtitle2" color={"green"}>
                Click Continue to lock the receivables.
              </Typography>
            )}
            <Stack flexDirection={"row"} gap={1}>
              <LoadingButton
                variant="contained"
                onClick={saveDraft}
                loading={isDraftLoading}
                disabled={
                  isPaymentLock ||
                  !(
                    usedReceivables.reduce(
                      (total: number, item: any) =>
                        total + item.receivablesToBeUsed,
                      0
                    ) +
                      +watch("balanceUsed") ==
                    paymentSummaryData.amount
                  )
                }
                sx={{
                  background: "#303981",
                  borderRadius: 2,
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: "#343d84db",
                  },
                }}
              >
                Continue
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={resetDraft}
                disabled={!isPaymentLock}
                sx={{
                  background: "#303981",
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#343d84db",
                  },
                }}
              >
                Reset
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
      )}

      {interestTable.length > 0 && (
        <Stack sx={{ m: 2 }}>
          <Typography
            fontFamily={"Public Sans"}
            fontWeight={700}
            variant="h5"
            my={1}
          >
            Interest Details
          </Typography>
          <Card>
            <TableContainer>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Amount Type</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Days</StyledTableCell>
                    <StyledTableCell>Fees</StyledTableCell>
                    <StyledTableCell>Interest</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interestTable.map((row: any) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {row.amountType ? "Bank Balance" : "Receivables"}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={
                          !row.amountType
                            ? { color: "#F58336" }
                            : { color: "black" }
                        }
                      >
                        {row.amount}
                      </StyledTableCell>
                      <StyledTableCell>{row.paymentDays} Days</StyledTableCell>
                      <StyledTableCell>{row.convenienceFees}</StyledTableCell>
                      <StyledTableCell sx={{ color: "green" }}>
                        {row.approxInterest}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Typography variant="body1" fontFamily={"Public Sans"} my={2}>
            Disclaimer
          </Typography>
          <Typography
            variant="caption"
            fontWeight={500}
            fontFamily={"Public Sans"}
          >
            The displayed Fixed Deposit interest rates are current as of this
            moment and are subject to change. Rates are updated every 15 seconds
            to provide you with the most accurate information. Please note that
            the actual interest rates applicable to your Fixed Deposit may
            differ at the time of confirmation. We recommend verifying the rates
            before finalizing any transactions or investments.
          </Typography>
          <Typography
            variant="h6"
            color={"green"}
            noWrap
            textAlign={"end"}
            mr={2}
          >
            Total Interest :{" "}
            {interestTable
              .reduce(
                (total: number, item: any) => total + item.approxInterest,
                0
              )
              .toFixed(2)}
          </Typography>
        </Stack>
      )}

      <Stack flexDirection={"row"} justifyContent={"space-between"} m={3}>
        <Button
          onClick={() => handleStepper(0)}
          sx={{
            color: "#303981",
          }}
          disabled={!!interestTable.length}
        >
          Previous
        </Button>
        <Button
          onClick={() => {
            handleStepper(2);
            confirmDetailData({
              orderDetails: {
                business_id:
                  paymentSummaryData?.businessId ||
                  BusinessDetailsForPayment?.businessDetails?.user,
                paymentType: paymentSummaryData.paymentMethod,
                orderType: paymentSummaryData.paymentType,
                paymentCombination: "BB",
                remaks: paymentSummaryData.desc,
                title: paymentSummaryData.title,
                POPI: paymentSummaryData.PO,
                orderAmount: +paymentSummaryData.amount,
                paymentIdentifier: "buyer",
                paymentDays: "",
                fromPR: false,
                prId: "",
                paymentSettlementDate: dayjs(paymentSummaryData.date),
              },
              interestTable: interestTable,
              milestoneDetails: [
                {
                  dueDate: dayjs(paymentSummaryData.date),
                  days: daysConversionFromDate(paymentSummaryData.date),
                  // ApproxInterest: interestTable.reduce(
                  //   (total: number, item: any) => total + item.approxInterest,
                  //   0
                  // ),
                  amount:
                    +getValues("balanceUsed") +
                    interestTable.reduce(
                      (total: number, item: any) =>
                        !item.amountType ? total + item.amount : total + 0,
                      0
                    ),
                  usingBB: +getValues("balanceUsed"),
                  usingRC: interestTable.reduce(
                    (total: number, item: any) =>
                      !item.amountType ? total + item.amount : total + 0,
                    0
                  ),
                  receivablesUsedForComplition: usedReceivables.map(
                    (elem: any) => {
                      return {
                        orderId: elem.orderId,
                        milestoneId: elem.id,
                        amount: elem.receivablesToBeUsed,
                        convenienceFee: elem.convenienceFee,
                        isFDAllowed: elem.isFDAllowed,
                        FDDays: elem.fdDays,
                        approxInterest: elem.approxInterest,
                      };
                    }
                  ),
                },
              ],
            });
          }}
          variant="contained"
          sx={{
            background: "#303981",
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "#343d84db",
            },
          }}
          disabled={!interestTable.length}
        >
          Next
        </Button>
      </Stack>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer>
            <Table sx={{ minWidth: 720 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Assured Receivables</StyledTableCell>
                  <StyledTableCell>Usable Receivables</StyledTableCell>
                  <StyledTableCell>AP Receivables fee (0.2%)</StyledTableCell>
                </TableRow>
              </TableHead>
              {allReceiablesData.length > 0 ? (
                <>
                  <TableBody>
                    {allReceiablesData.map((row: any) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                          {new Date(row.dueDate).toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {(row.amount - row.platformFee).toFixed()}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {parseFloat(row.eligibleAmount).toFixed(2)}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ textAlign: "center", color: "red" }}
                        >
                          {parseFloat(row.platformFee).toFixed(2)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </>
              ) : (
                <Stack textAlign="center">
                  <Typography16
                    color="orange"
                    text="No Records Found !"
                  ></Typography16>
                </Stack>
              )}
            </Table>
          </TableContainer>
          <Stack flexDirection={"row"} justifyContent={"end"} mt={2}>
            <Button
              variant="contained"
              sx={{
                background: "#303981",
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: "#343d84db",
                },
              }}
              onClick={handleClose}
            >
              close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default React.memo(ConfigurePayment);
